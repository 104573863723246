import { Circle_Marker } from './../models/Circle_Marker';
import { Marker, MarkerDragObj } from './../models/Marker';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {  LatLngLiteral, MouseEvent } from '../../../LibrerieCustom/agm-core-1.1.0';

@Component({
  selector: 'app-mappa',
  templateUrl: './mappa.component.html',
  styleUrls:['./mappa.component.css']
})
export class MappaComponent implements OnInit {
  @Input() zoom: number = 10;
  @Input() centerMaplat: number = 0;
  @Input() centerMaplon: number = 0;
  @Input() markers: Marker[] = [];
  @Input() circle: Circle_Marker;//= { latitude: this.centerMaplat, longitude: this.centerMaplon, radius: 50000};
  @Input() hasCircle: boolean = true;
  @Output() onMarkerClick = new EventEmitter();
  @Output() onMapClick = new EventEmitter();
  @Output() onMarkerDrag = new EventEmitter<MarkerDragObj>();
  @Output() onCircleDrag = new EventEmitter<LatLngLiteral>();
  @Output() onCircleClick = new EventEmitter<Circle_Marker>();
  @Output() onCircleRangeChange = new EventEmitter<number>();
  @Output() onCircleMarkerChange = new EventEmitter<Circle_Marker>();
  backupMapCenter: LatLngLiteral = { lat: this.centerMaplat, lng: this.centerMaplon };
  ngOnInit() {
    this.backupMapCenter.lat = this.centerMaplat;
    this.backupMapCenter.lng = this.centerMaplon;
  }
  clickedMarker(val: Marker) {
    this.onMarkerClick.emit(val);
  }
  mapClicked($event: MouseEvent) {
    this.onMapClick.emit($event);
  }
  markerDragEnd(m: Marker, $event: MouseEvent) {
    m.lat = $event.coords.lat;
    m.lng = $event.coords.lng;
    this.onMarkerDrag.emit({ marker: m, mouseEvent: $event})
  }
  clickedCircleMarker($event: MouseEvent) {
    this.onCircleClick.emit(this.circle);
  }
  circleMarkerCenterChange(coordinate: LatLngLiteral) {
    this.circle.latitude = coordinate.lat;
    this.circle.longitude = coordinate.lng;
    this.onCircleDrag.emit(coordinate);
  }
  circleMarkerDragEnd() {
    this.onCircleMarkerChange.emit(this.circle);
  }
  rangeCircleMarkerChanged(range: number) {
    this.onCircleRangeChange.emit(range);
    this.circle.radius = range;
    this.onCircleMarkerChange.emit(this.circle);
  }
  mapCenterChange(coordinate: LatLngLiteral) {
    this.backupMapCenter = coordinate;
  }
  mapCenterChanged() {
    this.centerMaplat = this.backupMapCenter.lat;
    this.centerMaplon = this.backupMapCenter.lng;
  }
}

