import {Component, Input, OnInit} from '@angular/core';
import {Permission} from "../../Login/_guards/Permission";
import {VoceProgetto} from "../../Progetti/Models/VoceProgetto";
import {ApiPathsService} from "../../api-paths.service";
import {NgbModal} from "../../../LibrerieCustom/NgBootstrap";

@Component({
  selector: 'app-promozioni',
  templateUrl: './promozioni.component.html',
  styleUrls: ['./promozioni.component.css']
})
export class PromozioniComponent implements OnInit {
  @Input() HasFilter: boolean;
  @Input() HasHeader: boolean;
  Lista: any[]

  constructor(public permission: Permission, private apiPaths: ApiPathsService) { }

  ngOnInit(): void {
    this.refresh();
  }
  refresh(){
    this.apiPaths.ClassicGet("/anagrafica/clienti/promozioni").subscribe(data=>{
      this.Lista = data.map(x=> {return {id: x.id, codice: x.codice, promozioni: x.listini.map(y=>{return {value: y}}), nascondi: x.nascondi, nome: x.nome, listino: x.listino, listini: x.listini}});
    })
  }
  edit(item: any) {
    if(item.modifica ) {
      this.apiPaths.ClassicPost("/anagrafica/clienti/promozioni/update ", {id: item.id, listini: item.promozioni.map(x=>x.value)}).subscribe(() => {
        this.refresh();
      });
    }
    else {
      item.modifica = !item.modifica;
    }
  }
  delete(item: VoceProgetto) {
    if(item.modifica && item.id)
      this.refresh();
  }

  addPromozione(item: any) {
    if(!item?.promozioni)
      item.promozioni = [];
    item.promozioni.push({value:""});
  }


  removePromozione(i: number, item: any) {
    item.promozioni.splice(i,1);
  }
}
