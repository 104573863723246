<div *ngIf="id"  class="">
  <h4 class="card-title d-flex align-items-center " style="background-color: #87858525; min-height: 40px">
    &nbsp;
    {{name ?? 'Allegati'|translate}}
    &nbsp;
    <span *ngIf="collectionSize > 0 && ListaFile"  class="badge badge-info badge-pill px-3 font-weight-bold font-14">{{collectionSize}}</span>
  </h4>
  <div  *ngIf="listafile && listafile.length > 0 && ListaFile"  class="row">
    <table  class=" table bg-white table-hover align-middle mb-0 ">
      <thead>
      <tr>
        <th>Nome</th>
        <th scope="col">Anteprima</th>
        <th scope="col">Descrizione</th>
        <th scope="col">Data</th>

        <ng-container *ngFor="let campo of toViewCampiAggiuntivi">
          <th scope="col" *ngIf="permission.isntOperativeRoles(campo.prohibitedRoles) && permission.isOperativeRoles(campo.AllowedRoles)" >{{campo.nome}}</th>
        </ng-container>
        <th scope="col">Azioni</th>
      </tr>
      </thead>
      <tbody >
      <tr *ngFor="let item of listafile">
        <td (click)='download(item)'>
          <span class="text-dark font-medium font-16">{{item.file}}</span>
        </td>
        <td >
          <div class="el-card-avatar el-overlay-1 d-flex justify-items-center" *ngIf="includesExtensions(item, ['.pdf', '.jpg', '.jpeg', '.png']) " >
            <img style="max-height: 200px; width: auto;" *ngIf="includesExtensions(item, ['.jpg', '.jpeg', '.png']) && authenticationService?.currentUserValue?.access_token" [src]="apipathsservice?.getBaseAPI() + '/file/download/' + item.id?.toString()+ '?token=' + authenticationService?.currentUserValue?.access_token"  alt="" />
            <pdf-viewer
              *ngIf="includesExtensions(item, ['.pdf'])"
              [src]="apipathsservice?.getBaseAPI() + '/file/download/' + item.id?.toString()+ '?token=' + authenticationService?.currentUserValue?.access_token" [render-text]="true" [autoresize]="true" [zoom]="0.2" [zoom-scale]="'page-width'" [external-link-target]="'blank'"
              style=" display: block; width: 100%; height: 200px; "
            ></pdf-viewer>
          </div>
        </td>
        <td class="align-middle"  (click)='download(item)'>
            <span >
               {{ item.description }}
            </span>
        </td>
        <td class="align-middle" (click)='download(item)'>
            <span>
              {{item.date | date: 'dd/MM/yyyy'}}
            </span>
        </td>
        <ng-container *ngFor="let campo of toViewCampiAggiuntivi">
          <td class="align-middle">
            <app-field-viewer [(DropdownSources)]="DropdownSources" [(Campi)]="toViewCampiAggiuntivi"  [Oggetto]="item"></app-field-viewer>
          </td>
        </ng-container>
        <td class="align-middle">
          <a href="javascript:void(0)" *ngIf="includesExtensions(item, ['.pdf', '.jpg', '.jpeg', '.png'])" class="link font-16 text-info font-medium mr-2" (click)="view(item)">
            <i class="fas fa-eye"></i>
          </a>
          <a href="javascript:void(0)" class="link font-16 font-weight-bold" (click)='download(item)'>
            <i-feather name="download" class="feather-sm"></i-feather>
          </a>
          <a *ngIf="environment['fileUpdate'] && GestioneFile"  href="javascript:void(0)" class="link font-16 font-weight-bold" (click)='openupdatemodal(item)'>
            <i class="fas fa-upload"></i>
          </a>
          &nbsp;
          <a href="javascript:void(0)" *ngIf="GestioneFile" class="link font-16 text-danger font-weight-bold" (click)='delete(item)'>
            <i-feather name="trash-2" class="feather-sm"></i-feather>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <hr>
  <div class="row" *ngIf="GestioneFile">
    <div class="col-12" *ngIf="campiAggiuntivi">
      <app-field-viewer  [(DropdownSources)]="DropdownSources" [(Campi)]="campiAggiuntivi"  [(Oggetto)]="tosend"></app-field-viewer>
    </div>
    <div class="col-sm-12 col-lg-6">
      <angular-file-uploader #fileUpload [(config)]="afuConfig" [(allowedFiles)]="allowedFiles" (onResetClick)="onResetClick()" (ApiResponse)="DocUploaded($event)">
      </angular-file-uploader>
    </div>

    <div class="col-sm-12 col-lg-6">
      <div class="form-group">
        <label for="descrizione" class="control-label col-form-label">Descrizione</label>
        <textarea type="text" [(ngModel)]='descrizione' (change)="descrizioneCambiata()"  class="form-control" id="descrizione" placeholder="Descrizione"></textarea>
      </div>
      <div class="d-md-flex align-items-center p-3">
        <button type="submit" (click)="Save()"  class=" btn  btn-success p-1 waves-effect waves-light">
          <i-feather name="upload" class="feather-md "></i-feather>
          <span class="ml-1 text-white "> Upload </span>
        </button>
      </div>
    </div>
  </div>
</div>

