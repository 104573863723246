import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor() { }
  //
  // downloadFile(response) {
  //   fileName = fileName.substring(0, fileName.length );
  //   let extension = fileName.split('.')[1].toLowerCase();
  //   var newBlob = new Blob([response.body], { type: this.createFileType(extension) })
  //   // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
  //   //   window.navigator.msSaveOrOpenBlob(newBlob);
  //   //   return;
  //   // }
  //   const data = window.URL.createObjectURL(newBlob);
  //   var link = document.createElement('a');
  //   link.href = data;
  //   link.download = fileName;
  //   link.click();
  //   setTimeout(() => {
  //     window.URL.revokeObjectURL(data);
  //   }, 400)
  // }
  downloadFile(response) {
    var fileName = response.headers.get('content-disposition').split('filename=')[1].replaceAll('"','')//.split(';')[0]
    let extension = fileName.split('.')[1].toLowerCase();
    console.log(fileName);
    console.log(extension);
    var newBlob = new Blob([response.body], { type: this.createFileType(extension) })
    const data = window.URL.createObjectURL(newBlob);
    var link = document.createElement('a');
    link.href = data;
    link.download = fileName;
    link.click();
    setTimeout(() => {
      window.URL.revokeObjectURL(data);
    }, 400)
  }

  createFileType(e): string {
    let fileType: string = "";
    if (e == 'pdf' || e == 'csv') {
      fileType = `application/${e}`;
    }
    else if (e == 'jpeg' || e == 'jpg' || e == 'png') {
      fileType = `image/${e}`;
    }
    else if (e == 'txt') {
      fileType = 'text/plain';
    }

    else if (e == 'ppt' || e == 'pot' || e == 'pps' || e == 'ppa') {
      fileType = 'application/vnd.ms-powerpoint';
    }
    else if (e == 'pptx') {
      fileType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    }
    else if (e == 'doc' || e == 'dot') {
      fileType = 'application/msword';
    }
    else if (e == 'docx') {
      fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    }
    else if (e == 'xls' || e == 'xlt' || e == 'xla') {
      fileType = 'application/vnd.ms-excel';
    }
    else if (e == 'xlsx') {
      fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    return fileType;
  }

}

