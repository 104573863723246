
<!--
  ~ .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .  . .  .
  ~    .       .       .       .       .       .       .       .       .       .       .       .
  ~      . :SXt. .  :%X%. . ..%S%: . .    . .    .  .    .  .    .  .    .  .    .  .    .  .    .  . .
  ~  .   .;8XSXt8 .S8@SX8@  888SS8t.    ..8  %t    .  .      .       .       .   ;X  X:      .
  ~    . @%@:8;S8 t8%t8 X8.:88%8.X8X     ;8;:S; .       .  .   . ;;.   .  .    . t8.:X:.t..    .  . .  .
  ~   .  8XXS tS8;X88S.SX8::8X; 8X8@ .  .:X;     . .  .   .      t@     .   .   .88    t;8   .   .
  ~      8S88:@8@8888S X8888888.8X8@   . @ . . .  ...   .   .. . ;8  .    .   . .8S .  %8: .   .    . .
  ~   . .8X88. ;8S%8;.  .8;:8%..8@8@   :S88.S@ ;t@::S S.  @;XttSS88;;;8;;   . %t:@8%:t X8; 8@% :; .
  ~      8@@S  .  ...   .. .:. .8X8@ .   S;.:  X8    ;8X t8S     t8   .@;  .88; .8%. . @8.  :8 .8   . .
  ~   . .8@@8;  .     .  .     .8X8@    .tX;  ;88.    X@ t8t     t8 .  88S t8@  :8S    @@%   t88.
  ~      @@88:     .      .  . .8@8@  . .X .  :8: .  .@S t8@     S@.  . 88%8:.  .8S .  88:  X8%8S.  .  .
  ~   .  8X88. .  .   . .   .  .8X8@     X : ..88t%;S@8X %8%  . .:8.     %88t  .:8S   .X8% S8S:X88:
  ~     .8XXS    .   .         .8X8@ . ..88.    tXX SS%  :8t     @8. .  .888.   .@S .  tX: 88.. SS:  .
  ~   .  8@88:.    .     . .  . 8X8@    .:. .    .: .  . ..  . .  .     S8%. .  .     .    ..       .  .
  ~     .@@88:  .     .         8X8@    .     . .   .      .     .  .   SX    .   .      .      .
  ~   .  8XX8;    .    .  .  . .8X8X .   . .      .   .  .    .       .88X. .      . . .    .  .  .  .
  ~      ;. %  .    .       .  .;:;:.  .     .  .           .   . .  . :;      . .        .         .  .
  ~   . . .  .   .   .  .             .   .    .   . . .  .               .  .      .  .    . .  .
  ~        .       .      .  . .  .     .   .    .       .   . .  . .  .    .   .        .        .  .
  ~   .  .    . .     .    .     .  .     .   .     .  .   .            .  .      . . .    .  . .     .
  ~
  ~ Copyright FortyFix(c) 2023.
  ~ Title: horizontal-sidebar.component.html;
  ~ Path: /Volumes/Dati 1/SbSW_SoftwareBySystem_Works/KI_KlimaItalia/GesKI_sbs_gestionale_Klimautalia_front_angular/src/app/layouts/horizontal-sidebar/horizontal-sidebar.component.html;
  ~ Description: ;
  ~ Author: giovannibattistadellaporta;
  ~ Created at: 05/09/23, 17:47;
  ~ Updated at: 05/09/23, 16:43;
  ~
  ~
  -->

<hr style="
    background-color: white;
    height: 1.5px;
    width: 100%;
    padding: 0px;
    margin: 0;
">
<nav class="sidebar-nav">
    <ul id="sidebarnav" style="justify-content: center;" *ngIf="sidebarnavItems">
        <!-- First level menu -->
        <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem.title"
            *ngFor="let sidebarnavItem of sidebarnavItems"
            [routerLinkActive]="hasSubmenu(sidebarnavItem)? '' : 'active'">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true">{{sidebarnavItem.title | translate}}</div>
            <a class="sidebar-link waves-effect waves-dark"

               [routerLink]="[getPath(sidebarnavItem?.path)] "
               [queryParams]="getQuerystring(sidebarnavItem?.path)"
                [ngClass]="[sidebarnavItem.class ?? '']" *ngIf="!sidebarnavItem.extralink;"
                (mouseover)="addExpandClass(sidebarnavItem.title)"
                [routerLinkActive]="hasSubmenu(sidebarnavItem) != 0 ? '' : 'active'">
                <!-- <i [ngClass]="[sidebarnavItem.icon]"></i> -->
<!--                <i-feather *ngIf="sidebarnavItem?.icon" [name]="sidebarnavItem?.icon??''" [ngClass]="[sidebarnavItem?.icon ?? '']"></i-feather>-->
                <span class="hide-menu">{{sidebarnavItem?.title | translate}}</span>
            </a>
            <!-- Second level menu -->
            <ul aria-expanded="false" class="collapse first-level" *ngIf="hasSubmenu(sidebarnavItem)"
                [ngClass]="{'in' : showMenu === sidebarnavItem.title }">
              <ng-template *ngFor="let sidebarnavSubItem of getVisibleItem(sidebarnavItem.submenu)">
                <li class="sidebar-item"
                    [class.active]="showSubMenu === sidebarnavSubItem.title"
                    [routerLinkActive]="hasSubmenu(sidebarnavSubItem) ? '' : 'active'">
                    <a class="sidebar-link"
                       [routerLink]="hasSubmenu(sidebarnavSubItem) ? null : [getPath(sidebarnavSubItem?.path)]"
                       [queryParams]="hasSubmenu(sidebarnavSubItem) ? null : getQuerystring(sidebarnavItem?.path)"
                        [routerLinkActive]="hasSubmenu(sidebarnavSubItem) ? '' : 'router-link-active'"
                        [ngClass]="[sidebarnavSubItem.class ?? '']" *ngIf="!sidebarnavSubItem.extralink;"
                        (mouseover)="addActiveClass(sidebarnavSubItem.title)">
                        <i *ngIf="sidebarnavSubItem.icon" [ngClass]="[sidebarnavSubItem.icon]"></i>
                        <span class="hide-menu">{{sidebarnavSubItem.title | translate}}</span>
                    </a>
                    <!-- Third level menu -->
                    <ul aria-expanded="false" class="collapse second-level" *ngIf="hasSubmenu(sidebarnavSubItem)"
                        [ngClass]="{'in' : showSubMenu === sidebarnavSubItem.title }">
                        <li class="sidebar-item" *ngFor="let sidebarnavSubsubItem of getVisibleItem(sidebarnavSubItem.submenu)"
                             [ngClass]="[sidebarnavSubsubItem.class ?? '']" >
                            <a class="sidebar-link"
                            [routerLinkActive]="hasSubmenu(sidebarnavSubsubItem) ? '' : ''"
                            [routerLink]="hasSubmenu(sidebarnavSubsubItem) ? null : [sidebarnavSubsubItem.path]"
                                *ngIf="!sidebarnavSubsubItem.extralink;"
                                >
                                <i *ngIf="sidebarnavSubsubItem.icon" [ngClass]="[sidebarnavSubsubItem.icon]"></i>
                                <span class="hide-menu">{{sidebarnavSubsubItem.title | translate}}</span>
                            </a>
                        </li>
                    </ul>
                </li>
              </ng-template>
            </ul>
        </li>
    </ul>
</nav>
