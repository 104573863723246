import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as shape from 'd3-shape';
import { generateData } from '../../Dashboard/chartData';

@Component({
  selector: 'app-group-vertical-bar-chart',
  templateUrl: './group-vertical-bar-chart.component.html',
  styleUrls: ['./group-vertical-bar-chart.component.scss']
})
export class GroupVerticalBarChartComponent {
  dateData: any[];
  dateDataWithRange: any[];
  ricercaform: FormGroup;
  submittedricerca;
  //viewsetting
  showXAxis = true;
  showYAxis = true;
  showXAxisLabel = true;
  showYAxisLabel = true;
  showGridLines = true;
  showLegend = false;
  roundDomains = false;
  schemeType = 'ordinal';
  barPadding = 8;
  groupPadding = 20;
  // data
  @Input() xAxisLabel;
  @Input() yAxisLabel;
  @Input() titolo: string;
  @Input() data: any[];
  @Input() hasSearch = true;
  @Input() colorScheme = {
    domain: ['#4fc3f7', '#fb8c00', '#7460ee', '#f62d51', '#20c997', '#2962FF','#9a5510','#bdd5b5','#47becd','#686f47','#68113c']
  };
  @Input() tooltipDisabled = false;
  @Input() range = false;
  @Input() gradient = false;
  constructor(private formBuilder: FormBuilder) {
    this.ricercaform = this.formBuilder.group({
      cerca: [''],
      inizio: ['', Validators.required],
    });

  }
  get f() { return this.ricercaform.controls; }
  get dateDataWithOrWithoutRange() {
    if (this.range) {
      return this.dateDataWithRange;
    } else {
      return this.dateData;
    }
  }
  curve = shape.curveLinear;

  select(data:string) {
    console.log('Item clicked', data);
  }
  onLegendLabelClick(entry:string) {
    console.log('Legend clicked', entry);
  }
  Search() {
    this.submittedricerca = true;
    if (this.ricercaform.invalid) { return; }
    this.submittedricerca = false;
  }

}
