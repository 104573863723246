import { Template } from './../Models/Template';
import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subscription } from 'rxjs';
import { StampeStoreService } from './../_service/StampeStore.service';

@Component({
  selector: 'app-mostra-marker',
  templateUrl: './mostra-marker.component.html'
})
export class MostraMarkerComponent implements OnInit {
  subscriptions: Subscription = new Subscription();
  modelli: Template[] = []
  modelliFiltrati: Template[] = []
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    textField: 'modello',
    idField:'id',
    searchPlaceholderText: "inserire almeno 3 caratteri",
    allowSearchFilter: true,
    allowRemoteDataSearch: true,
  };
  Selection: Template[] = [];
  Markers: string;
  constructor(
    private stampeStore: StampeStoreService,
  ) { }
  ngOnInit() {
    this.stampeStore.getMarkers();
    this.subscriptions.add(this.stampeStore.$markers.subscribe((items) => {
      if (items !== null && items !== undefined) {
        this.modelli = items;
        this.modelliFiltrati = items;
      }
    }));
  }
  GetMarkers() {
    this.Markers = '';
    if(this.Selection && this.Selection[0])
      this.Markers = this.modelliFiltrati.find(x => x.id == this.Selection[0].id).markers;
  }
  filtroDropdown(item: string) {
    if (item.length >= 3) {
      this.modelliFiltrati = this.modelli.filter(x => x.modello.toLowerCase().includes(item.toLowerCase()));
    }
  }
}
