import { Template } from './../Models/Template';
import { BehaviorSubject } from 'rxjs';
import { ApiRestService } from './ApiRest.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StampeStoreService {
  constructor(private apirest: ApiRestService) { }
  private LISTA_TEMPLATE = new BehaviorSubject<Template[]>([]);
  private LISTA_Markers = new BehaviorSubject<Template[]>([]);
  private EDITABLE = new BehaviorSubject<boolean>(false);
  private SHOW_ADD_FORM = new BehaviorSubject<boolean>(false);
  private SELECTED_Template = new BehaviorSubject<Template>(null);
  private dataStore: {
    Templates: Template[],
    Markers: Template[],
    Editable: boolean,
    ShowAddForm: boolean,
    SelectedTemplate: Template
  } = {
      Templates: [],
      Markers: [],
      Editable: false,
      ShowAddForm: false,
      SelectedTemplate:null,
    }
  readonly $templates = this.LISTA_TEMPLATE.asObservable();
  readonly $markers = this.LISTA_Markers.asObservable();
  readonly $editable = this.EDITABLE.asObservable();
  readonly $showAddForm = this.SHOW_ADD_FORM.asObservable();
  readonly $selectedTemplate = this.SELECTED_Template.asObservable();
  refreshTemplates = () => this.LISTA_TEMPLATE.next(Object.assign({}, this.dataStore).Templates);
  refreshMarkers = () => this.LISTA_Markers.next(Object.assign({}, this.dataStore).Markers);
  refreshEditable = () => this.EDITABLE.next(Object.assign({}, this.dataStore).Editable);
  refreshShowAddForm = () => this.SHOW_ADD_FORM.next(Object.assign({}, this.dataStore).ShowAddForm);
  refreshSelectedTemplate = () => this.SELECTED_Template.next(Object.assign({}, this.dataStore).SelectedTemplate);


  //#region azioni
  getTemplates() {
    this.apirest.getListaTemplate().subscribe(data => {
      this.dataStore.Templates = data;
      this.refreshTemplates();
      return this.$templates;
    });
  }
  getMarkers() {
    this.apirest.getListaMarkers().subscribe(data => {
      this.dataStore.Markers = data;
      this.refreshMarkers();
      return this.$markers;
    });
  }
  updateSelectedTemplate(value: Template) {
    this.dataStore.SelectedTemplate = value;
    this.refreshSelectedTemplate();
  }
  updateEditable(value: boolean) {
    this.dataStore.Editable = value;
    this.refreshEditable();
  }
  updateShowAddForm(value: boolean) {
    this.dataStore.ShowAddForm = value;
    this.refreshShowAddForm();
  }
  //#endregion
}
