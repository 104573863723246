<div class="card" *ngIf="data?.length>0 && tabbedView">
  <ul ngbNav #nav="ngbNav" [activeId]="'it'" class="nav-tabs">
    <li [ngbNavItem]="obj.lang" *ngFor="let obj of data">
      <a ngbNavLink> {{obj.lang}} </a>
      <ng-template ngbNavContent >
        <div class="row">
          <div class="col-12" *ngIf="obj?.default && data.length > 1">
            <div class="d-flex justify-content-end" >
              <button class="btn btn-outline-info rounded-pill" style="" (click)="translate(obj)" type="button">{{ 'Traduci' | translate}}</button>
            </div>
          </div>
          <div  [class.col-md-4]="col4" [class.col-12]="!col4" *ngFor="let campo of obj?.TranslateInputs" >
            <div class="form-group">
              <label  class="control-label col-form-label">{{campo.label | translate}}&nbsp;{{obj.lang}}</label>
              <input *ngIf="campo.type != 'textarea'" [(ngModel)]="campo.value" [type]="campo.type" class="form-control"  placeholder="{{campo.label | translate}}">
              <textarea *ngIf="campo.type == 'textarea'" [(ngModel)]="campo.value"  class="form-control"  placeholder="{{campo.label | translate}}"></textarea>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>


<div class="card" *ngIf="data.length > 0 && !tabbedView">
  <div *ngFor="let obj of data; let i = index">
    <div class="row" >
      <div class="col-12" *ngIf="obj?.default && data.length > 1">
        <div class="d-flex justify-content-end" >
          <button class="btn btn-outline-info rounded-pill" style="" (click)="translate(obj)" type="button">{{ 'Traduci' | translate}}</button>
        </div>
      </div>
      <div  [class.col-md-4]="col4 && col4Ids.includes(campo.id)" [class.col-md-3]="col4 && !col4Ids.includes(campo.id)" [class.col-12]="!col4" *ngFor="let campo of obj?.TranslateInputs" >
        <div class="form-group">
          <label  class="control-label col-form-label">{{campo.label | translate}}&nbsp;{{obj.lang}}</label>
          <input *ngIf="campo.type != 'textarea'" [(ngModel)]="campo.value" [type]="campo.type" class="form-control"  placeholder="{{campo.label | translate}}">
          <angular-editor *ngIf="campo.type == 'textarea'" [config]="editorConfig" [(ngModel)]="campo.value"></angular-editor>
<!--          <textarea *ngIf="campo.type == 'textarea'" [(ngModel)]="campo.value"  class="form-control"  placeholder="{{campo.label | translate}}"></textarea>-->
        </div>
      </div>

    </div>
    <hr style="border-width: medium; border-color: #9b9b9b" *ngIf="i < data.length -1">
  </div>

</div>


