<div class="row">
  <div class="card col-12">
    <div class="card-body">
      <app-field-viewer [Campi]="CampiFiltro()" [(Oggetto)]="filtri" (OnChange)="OnFilter($event)"></app-field-viewer>
    </div>
  </div>
  <div class=" col-md-6" *ngFor="let campi of DashBoardComponent">
    <div class="card   align-items-center" *ngIf="campi.tipo == 'text'" [ngClass]="campi.class" (click)="Click(campi)">
      <div class="card-body text-white align-items-center">
        <div class="d-flex no-block">
          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>
          <div class="align-self-center">
            <h2 class="text-white m-t-10 m-b-0">{{campi.titolo}}</h2>
            <h1 class="m-t-0 text-white">{{campi.valore}}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="card" *ngIf="campi.tipo == 'verticalBar' && campi.valore" style="height: calc(50vh - 100px);">
      <div class="card-body text-white align-items-center dark">
        <h5 class="text-white m-t-10 m-b-0">{{campi.titolo}}</h5>
        <ngx-charts-bar-vertical class=" " [animations]="false" [yAxis]="true" [xAxis]="true" [showXAxisLabel]="true" [showYAxisLabel]="true" [results]="campi.VerticalBarData" >
        </ngx-charts-bar-vertical>
      </div>
    </div>
    <div class="card" *ngIf="campi.tipo == 'torta' && campi.valore" style="height: calc(50vh - 100px);">
      <div class="card-body text-white align-items-center">
        <h5 class="text-white m-t-10 m-b-0">{{campi.titolo}}</h5>
        <ngx-charts-advanced-pie-chart class="chart-container" [animations]="false"  [results]="campi.VerticalBarData" >
        </ngx-charts-advanced-pie-chart>
      </div>
    </div>
  </div>
</div>

