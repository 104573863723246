import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServerPaginationService {

  constructor() { }
  //restituisce la pagina
  paginadellalista(page,pagesizeserver) { //da 1 a pagesizeserver
    let result: number = ((page % pagesizeserver));
    if (result === 0) {
      result = pagesizeserver;
    }
    return result;
  }
  getPage(page, newpagegenerated:number, pagesizeserver: number = 4, ): number {

    if (page < newpagegenerated && (page - 1) % pagesizeserver === 0) {
      //this.refreshTablewithif(page, pagesizeserver,func);
    }
    else if (page < newpagegenerated) {
      while ((page - 1) % pagesizeserver !== 0) {
        page--;
        //this.refreshTablewithif(page, pagesizeserver,func);
      }
    }else if (page > newpagegenerated + pagesizeserver && (page - 1) % pagesizeserver === 0) {
      //this.refreshTablewithif(page, pagesizeserver,func);
    }
    else if (page > newpagegenerated + pagesizeserver) {
      while ((page - 1) % pagesizeserver !== 0) {
        page--;
        //this.refreshTablewithif(page, pagesizeserver,func);
      }
    }
    else if ((page - 1) % pagesizeserver === 0) {
      //func(page)
    }
    return page;
  }
  refreshTablewithif(page, pagesizeserver, func:Function) {
    if ((page - 1) % pagesizeserver === 0) {
      //func(page);
    }
  }

}
