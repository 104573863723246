<!--<div class="card bg-white">-->
<!--  <div class="card-body ">-->
<!--    <div class="row">-->
<!--      <div class="col-lg-4">-->
<!--        <div class="form-group">-->
<!--          <label for="data_pagamento_p" class="control-label col-form-label">Data Inizio: </label>-->
<!--          <input type="date" [(ngModel)]="inizio" class="form-control" (ngModelChange)="refresh()" id="data_pagamento_p">-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-lg-4">-->
<!--        <div class="form-group">-->
<!--          <label for="data_pagamento_e" class="control-label col-form-label">Data Fine: </label>-->
<!--          <input type="date" [(ngModel)]="fine" class="form-control" (ngModelChange)="refresh()" id="data_pagamento_e">-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<div class="card" *ngIf="!((env['DashboardAddon'] && env['DashboardAddon']['giemmea'] )?? true)">
  <div class="card-body">
    <div class="row">
      <div class="col-lg-2 ">
        <label for="stato" class="control-label col-form-label"></label>
        <ng-multiselect-dropdown  [settings]="dropdownSettings" [placeholder]="'Periodo'" id="stato" [data]="periodi"
                                  [(ngModel)]="periodo" (ngModelChange)="refresh()">
        </ng-multiselect-dropdown>
      </div>
      <div class="col-lg-2 ">
        <label for="anno"  class="control-label col-form-label"></label>
        <input type="number" [(ngModel)]="anno" (ngModelChange)="refresh()" class="form-control" id="anno" >
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="!((env['DashboardAddon'] && env['DashboardAddon']['giemmea'] )?? false)">

  <div class=" col-md-3">
    <div class="card bg-warning align-items-center" >
      <div class="card-body text-white align-items-center">
        <div class="d-flex no-block">
          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>
          <div class="align-self-center">
            <h2 class="text-white m-t-10 m-b-0">Numero Clienti</h2>
            <h1 class="m-t-0 text-white">{{data?.num_clienti}}</h1></div>
        </div>
      </div>
    </div>
  </div> <!--// numero clienti-->
  <div class=" col-md-3">
    <div class="card bg-info align-items-center">
      <div class="card-body text-white align-items-center">
        <div class="d-flex no-block ">
          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>
          <div class="align-self-center">
            <h2 class="text-white m-t-10 m-b-0">Numero Offerte</h2>
            <h1 class="m-t-0 text-white">{{data?.num_offerte}}</h1>
          </div>
        </div>
      </div>
    </div>
  </div> <!--// nOfferte-->
  <div class=" col-md-3">
    <div class="card bg-success align-items-center">
      <div class="card-body text-white align-items-center">
        <div class="d-flex no-block">
          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>
          <div class="align-self-center">
            <h2 class="text-white m-t-10 m-b-0">Lavorato</h2>
            <h1 class="m-t-0 text-white">{{data?.lavorato}}</h1></div>
        </div>
      </div>
    </div>
  </div>
  <div class=" col-md-3">
    <div class="card bg-danger align-items-center">
      <div class="card-body text-white align-items-center">
        <div class="d-flex no-block">
          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>
          <div class="align-self-center">
            <h2 class="text-white m-t-10 m-b-0">Da Incassare</h2>
            <h1 class="m-t-0 text-white">{{data?.da_incassare}}</h1></div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <app-group-vertical-bar-chart [xAxisLabel]="''" [hasSearch]="false" [yAxisLabel]="''" [data]="offertovendutofatturatoincassato"
                                  [titolo]="'Offerto - Venduto - Fatturato - Incassato' | translate" ></app-group-vertical-bar-chart>
  </div>
  <div class="col-md-6">
    <app-group-vertical-bar-chart [xAxisLabel]="''" [hasSearch]="false" [yAxisLabel]="''" [data]="FatturatoCostiChartData"
                                  [titolo]="'Fatturato - Costi - Incassato - Speso' | translate" ></app-group-vertical-bar-chart>
  </div>
<!--  <div class="col-md-6">-->
<!--    <app-line-chart [lineChartData]="ImportiChartData" [lineChartLabels]="ImportiChartLabels"-->
<!--                    [titolo]="'dashboard.importi' | translate"></app-line-chart>-->
<!--  </div>-->
<!--  <div class="col-md-6">-->
<!--    <app-line-chart [lineChartData]="FatturatoChartData" [lineChartLabels]="FatturatoChartLabels"-->
<!--                    [titolo]="'dashboard.Fatturato_tipologia' | translate"></app-line-chart>-->
<!--  </div>-->
  <div class="col-md-6">
    <app-line-chart [lineChartData]="VendutoCommercialeChartData" [lineChartLabels]="VendutoCommercialeChartLabels"
                    [titolo]="'Venduto per Commerciale' | translate"></app-line-chart>
  </div>
  <div class="col-md-6">
    <app-line-chart [lineChartData]="VendutoSegnalatoreChartData" [lineChartLabels]="VendutoSegnalatoreChartLabels"
                    [titolo]="'Venduto per Segnalatore' | translate"></app-line-chart>
  </div>
<!--  <div class="col-md-6">-->
<!--    <app-group-vertical-bar-chart [xAxisLabel]="''" [hasSearch]="false" [yAxisLabel]="''" [data]="ContrattiOfferteChartData"-->
<!--                                  [titolo]="'dashboard.contrattiofferte' | translate" ></app-group-vertical-bar-chart>-->
<!--  </div>-->
<!--  <div class="col-md-6">-->
<!--    <app-group-vertical-bar-chart [xAxisLabel]="''" [hasSearch]="false" [yAxisLabel]="''" [data]="ContrattiOfferteValChartData"-->
<!--                                  [titolo]="'dashboard.valcontrattiofferte' | translate" ></app-group-vertical-bar-chart>-->
<!--  </div>-->


<!--  <div class=" col-md-3">-->
<!--    <div class="card bg-dark align-items-center">-->
<!--      <div class="card-body text-white align-items-center">-->
<!--        <div class="d-flex no-block ">-->
<!--          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>-->
<!--          <div class="align-self-center">-->
<!--            <h2 class="text-white m-t-10 m-b-0">Numero Offerte Chiuse</h2>-->
<!--            <h1 class="m-t-0 text-white">{{data.num_offerte_chiuse}}</h1>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div> &lt;!&ndash;// nOfferteChiuse&ndash;&gt;-->
<!--  <div class=" col-md-3">-->
<!--    <div class="card bg-dark align-items-center">-->
<!--      <div class="card-body text-white align-items-center">-->
<!--        <div class="d-flex no-block">-->
<!--          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>-->
<!--          <div class="align-self-center">-->
<!--            <h2 class="text-white m-t-10 m-b-0">Numero Contratti</h2>-->
<!--            <h1 class="m-t-0 text-white">{{data.num_contratti}} </h1></div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div> &lt;!&ndash;// ncontratti&ndash;&gt;-->
<!--  <div class=" col-md-3">-->
<!--    <div class="card bg-dark align-items-center">-->
<!--      <div class="card-body text-white align-items-center">-->
<!--        <div class="d-flex no-block">-->
<!--          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>-->
<!--          <div class="align-self-center">-->
<!--            <h2 class="text-white m-t-10 m-b-0">Percentuale Conversione</h2>-->
<!--            <h1 class="m-t-0 text-white">{{data?.num_conversione?.replace('.',',')}} %</h1></div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div> &lt;!&ndash;// % Conversione&ndash;&gt;-->
<!--  <div class=" col-md-3">-->
<!--    <div class="card bg-dark align-items-center">-->
<!--      <div class="card-body text-white align-items-center">-->
<!--        <div class="d-flex no-block ">-->
<!--          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>-->
<!--          <div class="align-self-center">-->
<!--            <h2 class="text-white m-t-10 m-b-0">Valore di Conversione</h2>-->
<!--            <h1 class="m-t-0 text-white">{{data?.valore_conversione?.replace('.',',')}} %</h1>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div> &lt;!&ndash;// valore Conversione&ndash;&gt;-->
<!--  <div class=" col-md-3">-->
<!--    <div class="card bg-dark align-items-center">-->
<!--      <div class="card-body text-white align-items-center">-->
<!--        <div class="d-flex no-block ">-->
<!--          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>-->
<!--          <div class="align-self-center">-->
<!--            <h2 class="text-white m-t-10 m-b-0">Tempi di Accettazione</h2>-->
<!--            <h1 class="m-t-0 text-white">{{data.tempi_accettazione}} g</h1>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div> &lt;!&ndash;// tempi accettazione&ndash;&gt;-->
<!--  <div class=" col-md-3">-->
<!--    <div class="card bg-dark align-items-center">-->
<!--      <div class="card-body text-white align-items-center">-->
<!--        <div class="d-flex no-block">-->
<!--          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>-->
<!--          <div class="align-self-center">-->
<!--            <h2 class="text-white m-t-10 m-b-0">Fatturato</h2>-->
<!--            <h1 class="m-t-0 text-white">{{data?.fatturato_tot}} €</h1>-->

<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div> &lt;!&ndash;// fatturato&ndash;&gt;-->
<!--  <div class=" col-md-3">-->
<!--    <div class="card bg-dark align-items-center">-->
<!--      <div class="card-body text-white align-items-center">-->
<!--        <div class="d-flex no-block ">-->
<!--          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>-->
<!--          <div class="align-self-center">-->
<!--            <h2 class="text-white m-t-10 m-b-0">Tempi di Pagamento</h2>-->
<!--            <h1 class="m-t-0 text-white">{{data.tempi_pagamento}} g</h1>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div> &lt;!&ndash;// tempi Pagamento&ndash;&gt;-->
<!--  <div class=" col-md-3">-->
<!--    <div class="card bg-dark align-items-center">-->
<!--      <div class="card-body text-white align-items-center">-->
<!--        <div class="d-flex no-block">-->
<!--          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>-->
<!--          <div class="align-self-center">-->
<!--            <h2 class="text-white m-t-10 m-b-0">Numero Fornitori</h2>-->
<!--            <h1 class="m-t-0 text-white">{{data.num_fornitori}}</h1></div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div> &lt;!&ndash;// nfornitori&ndash;&gt;-->
<!--  <div class=" col-md-3">-->
<!--    <div class="card bg-dark align-items-center">-->
<!--      <div class="card-body text-white align-items-center">-->
<!--        <div class="d-flex no-block ">-->
<!--          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>-->
<!--          <div class="align-self-center">-->
<!--            <h2 class="text-white m-t-10 m-b-0">Numero Pagamenti</h2>-->
<!--            <h1 class="m-t-0 text-white">{{data.num_pagamenti}}</h1>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div> &lt;!&ndash;// npagamenti&ndash;&gt;-->
<!--  <div class=" col-md-3">-->
<!--    <div class="card bg-dark align-items-center">-->
<!--      <div class="card-body text-white align-items-center">-->
<!--        <div class="d-flex no-block ">-->
<!--          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>-->
<!--          <div class="align-self-center">-->
<!--            <h2 class="text-white m-t-10 m-b-0">Ordini</h2>-->
<!--            <h1 class="m-t-0 text-white">{{data?.ordini}} €</h1>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div> &lt;!&ndash;// Ordini&ndash;&gt;-->
<!--  <div class=" col-md-3">-->
<!--    <div class="card bg-dark align-items-center">-->
<!--      <div class="card-body text-white align-items-center">-->
<!--        <div class="d-flex no-block ">-->
<!--          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>-->
<!--          <div class="align-self-center">-->
<!--            <h2 class="text-white m-t-10 m-b-0">Ordini Chiusi</h2>-->
<!--            <h1 class="m-t-0 text-white">{{data?.ordini_chiusi}} €</h1>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div> &lt;!&ndash;// ordiniChiusi&ndash;&gt;-->
<!--  <div class="col-md-12">-->
<!--    <app-pie [single]="tipiClientichart" [titolo]="'Tipi di cliente'"></app-pie> &lt;!&ndash;clienti&ndash;&gt;-->
<!--  </div>-->

</div>
<div class="card" *ngIf="((env['DashboardAddon'] && env['DashboardAddon']['giemmea'] ) ?? true)">
  <div class="card-body">
    <div class="row">
      <div class="col-lg-2 ">
        <label  class="control-label col-form-label">Data Scadenza</label>
        <input type="date" [(ngModel)]="filtri.dataScaduti" (ngModelChange)="refresh()" class="form-control" >
      </div>
      <button type="button" (click)="SetProvincia('ba')" [ngClass]="filtri.provincia == 'ba'? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'" >
        <span class="ml-1  font-weight-bold">BA</span>
      </button>
      <button type="button" (click)="SetProvincia('le')" [ngClass]="filtri.provincia == 'le'? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'" >
        <span class="ml-1  font-weight-bold">LE</span>
      </button>
      <button type="button" (click)="SetProvincia('fo')" [ngClass]="filtri.provincia == 'fo'? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'" >
        <span class="ml-1  font-weight-bold">FO</span>
      </button>
      <button type="button" (click)="SetProvincia('bat')" [ngClass]="filtri.provincia == 'bat'? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'" >
        <span class="ml-1  font-weight-bold">BAT</span>
      </button>
      <button type="button" (click)="SetProvincia('ta')" [ngClass]="filtri.provincia == 'ta'? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'" >
        <span class="ml-1  font-weight-bold">TA</span>
      </button>
      <button type="button" (click)="SetProvincia('br')" [ngClass]="filtri.provincia == 'br'? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'" >
        <span class="ml-1  font-weight-bold">BR</span>
      </button>
      <button type="button" (click)="SetProvincia(null)" [ngClass]="filtri.provincia == null? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'" >
        <span class="ml-1  font-weight-bold">Tutte le Provincie</span>
      </button>




    </div>
  </div>
</div>
<div class="row" *ngIf="((env['DashboardAddon'] && env['DashboardAddon']['giemmea'] ) ?? false)">

  <div class=" col-md-3" (click)="goToList('S')">
    <div class="card bg-warning align-items-center">
      <div class="card-body text-white align-items-center">
        <div class="d-flex no-block">
          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>
          <div class="align-self-center">
            <h2 class="text-white m-t-10 m-b-0">Scaduti</h2>
            <h1 class="m-t-0 text-white">{{data?.scaduti ?? 0}}</h1></div>
        </div>
      </div>
    </div>
  </div> <!--//Scaduti-->
  <div class=" col-md-3" (click)="goToList('I')">
    <div class="card bg-info align-items-center">
      <div class="card-body text-white align-items-center">
        <div class="d-flex no-block ">
          <div class="m-r-20 align-self-center"><img src="assets/images/icon/expense-w.png" alt="Income" /></div>
          <div class="align-self-center">
            <h2 class="text-white m-t-10 m-b-0">In Scadenza</h2>
            <h1 class="m-t-0 text-white">{{data?.inScadenza ?? 0}}</h1>
          </div>
        </div>
      </div>
    </div>
  </div> <!--// in scadenza-->

</div>

