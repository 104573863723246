<div class="d-md-flex align-items-center  p-3">
    <span class="d-flex align-items-center p-2 ">
    <h5 class="mb-0 mr-1 text-dark font-weight-bold font-14"> Numero di template:</h5>
    <span class="badge badge-danger badge-pill px-3 font-weight-bold font-14">{{collectionSize}}</span>
    </span>
  <!-- Add Contact -->
  <div class="ml-auto">
    <button type="button" (click)="openModal(addModal)" class=" btn btn-success rounded-pill p-2" (click)='addTemplate()'><i
        class="fas fa-plus  font-14 mr-1 text-white"></i>
      <span class="ml-1 text-white font-weight-bold">Aggiungi</span>
    </button>
  </div>
</div>
<div class="card">
  <div class="table-responsive">
    <table class="table bg-white table-hover align-middle mb-0 ">
      <thead>
        <tr>
          <th>Nome File</th>
          <th scope="col">Descrizione</th>
          <th scope="col">Modello</th>
          <th scope="col">Sezione</th>
          <th scope="col">Azioni</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let co of listaTemplate ">
          <td>
            <span class="text-dark font-medium font-16">{{ co.file }} </span>
          </td>
          <td class="align-middle" style="width: 40%; word-break:break-word">
            <span>{{ co.description }}</span>
          </td>
          <td class="align-middle">
            <span>
              <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
                <i class="mdi mdi-file-excel"></i>
              </span>
              {{co.modello}}
            </span>
          </td>
          <td class="align-middle">
            <span>
              <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
                <i class="mdi mdi-format-list-bulleted"></i>
              </span>
              {{ co.sezione }}
            </span>
          </td>
          <td class="align-middle">
            <a href="javascript:void(0)" class="link font-16 font-weight-bold" (click)='download(co)'>
              <i-feather name="download" class="feather-sm"></i-feather>
            </a>
            &nbsp;
            <a href="javascript:void(0)" class="link font-16 text-danger font-weight-bold" (click)='delete(co)'>
              <i-feather name="trash-2" class="feather-sm"></i-feather>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #addModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">Template</h5>
    <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="input-group col-12">
        <div class="">
          <label class="input-group-text" for="nome">Nome Template:</label>
        </div>
        <input type="text" [(ngModel)]="descrtption" name="nome" class="form-control">
      </div>
      <div class="col-12">
        <label for="categorie" class="control-label col-form-label">Modello:</label>
        <ng-multiselect-dropdown style="width: 80%; " [placeholder]="'Seleziona'" name="categorie"
          [data]="modelliFiltrati" [(ngModel)]="modelloSelezionato" [settings]="dropdownSettings"
          (onFilterChange)="filtroDropdown($event)">
        </ng-multiselect-dropdown>
      </div>
      <div class="p-3 col-12">
        <angular-file-uploader #fileUpload [config]="afuConfig" (ApiResponse)="DocUploaded($event)"></angular-file-uploader>
      </div>
    </div>
    <div class="d-md-flex align-items-center p-3" style="padding-right: 25px;">
      <button (click)="saveModalButton(fileUpload)" class="ml-auto btn btn-rounded btn-success p-2 waves-effect waves-light">
        <span class="ml-1 text-white font-weight-bold"> Salva </span>
        <i-feather name="save" class="feather-md "></i-feather>
      </button>
    </div>
  </div>
</ng-template>





