import { DownloadService } from './../../shared/_services/download.service';
import { FileStoreService } from './../../shared/_services/file-store.service';
import { ApiRestService } from './../../shared/_services/api-rest.service';
import { Template } from './../Models/Template';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { StampeStoreService } from '../_service/StampeStore.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { AngularFileUploaderConfig } from './../../shared/file-uploader/angular-file-uploader.types';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import { AngularFileUploaderComponent } from './../../shared/file-uploader/angular-file-uploader.component';
import {ApiPathsService} from "../../api-paths.service";
import {AuthenticationService} from "../../Login/_services/authentication.service";

@Component({
  selector: 'app-lista-templates',
  templateUrl: './lista-templates.component.html'
})
export class ListaTemplatesComponent implements OnInit {
  parametri: HttpParams = new HttpParams();
  url: string = "";
  afuConfig: AngularFileUploaderConfig = {
    multiple: false,
    maxSize: 5,
    formatsAllowed: "doc,docx,xls,xlsx,odt,ods",
    uploadAPI: {
      url: this.url,
      method: "POST",
      headers: new HttpHeaders({
        //'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${this.authenticationService?.currentUserValue?.access_token}`,
      }),
      //responseType: 'application/json',
    },
    paramsType: "body",
    theme: "dragNDrop",
    hideProgressBar: false,
    hideResetBtn: true,
    hideSelectBtn: false,
    hideUploadButton: true,
    fileNameIndex: true,
    replaceTexts: {
      selectFileBtn: 'Seleziona il File',
      resetBtn: 'Reset',
      uploadBtn: 'Carica',
      dragNDropBox: 'Trascina Qui il file',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: 'Successfully Uploaded !',
      afterUploadMsg_error: 'Upload Failed !',
      sizeLimit: 'Size Limit'
    }
  };
  subscriptions: Subscription = new Subscription();
  collectionSize: number = 0;
  editable: boolean;
  showaddform: boolean;
  listaTemplate: Template[] = []
  // var modale
  descrtption: string = "";
  modelloSelezionato: Template[] = [];
  modelli: Template[] = []
  modelliFiltrati: Template[] = []
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    textField: 'modello',
    searchPlaceholderText: "inserire almeno 3 caratteri",
    allowSearchFilter: true,
    allowRemoteDataSearch: true,
  };
  constructor(
    private sharedApiRest: ApiRestService,
    private filestore: FileStoreService,
    private downloadService: DownloadService,
    private stampeStore: StampeStoreService,
    private modalService: NgbModal,
    private apirest: ApiRestService,
    private authenticationService: AuthenticationService,
    private apiPaths: ApiPathsService,
  ) { }

  ngOnInit() {
    this.stampeStore.getTemplates();
    this.filestore.SelectSection('stampa');
      this.url = '/stampa/add';
      this.afuConfig.uploadAPI.url = this.url.startsWith('/')? `${this.apiPaths.getBaseAPI()}${this.url}`: this.url;
    this.subscriptions.add(this.stampeStore.$templates.subscribe((items) => {
      if (items !== null && items !== undefined) {
        this.listaTemplate = items;
        this.collectionSize = items.length;
      }
    }));
    this.subscriptions.add(this.stampeStore.$markers.subscribe((items) => {
      if (items !== null && items !== undefined) {
        this.modelli = items;
        this.modelliFiltrati = items;
      }
    }));
    this.subscriptions.add(this.stampeStore.$editable.subscribe((items) => this.editable = items))
    this.subscriptions.add(this.stampeStore.$showAddForm.subscribe((items) => this.showaddform = items))

  }
  addTemplate() {

  }
  delete(file: Template) {
    if(confirm("Sei sicuro di voler eliminare questo template?"))
    this.filestore.deleteFile(file).subscribe((data) => {
      this.stampeStore.getTemplates();
    });
  }
  download(file: Template) {
    this.sharedApiRest.downloadFle(file.id).subscribe(data => {
      this.downloadService.downloadFile(data)
    });
  }
  openModal(targetModal: NgbModal) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
    this.stampeStore.getMarkers();
  }
  closeBtnClick() {
    this.modalService.dismissAll();
    //this.ngOnInit();
  }

  filtroDropdown(item: string) {
    if (item.length >= 3) {
      this.modelliFiltrati = this.modelli.filter(x=>x.modello.includes(item));
    }
  }
  DocUploaded(event) {
    this.closeBtnClick();
    this.stampeStore.getTemplates();
    //this.modelloSelezionato = [];
    this.descrtption = "";
  }
  saveModalButton(fileUpload: AngularFileUploaderComponent) {
    if (this.modelloSelezionato && this.modelloSelezionato !== null && this.modelloSelezionato.length > 0)
      fileUpload.uploadFiles({ 'id': this.modelloSelezionato[0].id.toString(), 'descrizione': this.descrtption });

  }
}
