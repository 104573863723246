<div class="card">
    <div class="card-body">
    <!-- ============================================================== -->
    <!-- Top Buttons -->
    <!-- ============================================================== -->
      <div class="d-md-flex align-items-center bg-white">
        <app-stampa [Stampe]="Stampe" *ngIf="Stampe && Stampe.length > 0"></app-stampa>
        <a href="javascript:void(0)" class="d-flex align-items-center p-2 rounded-pill bg-white">
          <h5 class="mb-0 mr-1 text-dark font-weight-bold font-14"> {{Title}}</h5>
        </a>
        <div class="ml-auto mt-3 mt-md-0">
          <button class="btn btn-outline-primary" *ngIf="Modificabile" (click)="addEvent()">
            {{AddButtonText}}
          </button>
        </div>
      </div>
<!--    <h3>-->
<!--      {{Title}}-->
<!--      <button class="btn btn-outline-primary " *ngIf="Modificabile" (click)="addEvent()">-->
<!--        {{AddButtonText}}-->
<!--      </button>-->
<!--      <app-stampa [Stampe]="Stampe" *ngIf="Stampe && Stampe.length > 0"></app-stampa>-->
<!--      <div class="clearfix"></div>-->
<!--    </h3>-->

    <hr>

    <div class="pt-3 row">
            <div class="col-md-4">
                <div class="btn-group">
                    <div class="btn btn-success" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="DateChange()">
                        Previous </div>
                    <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate" (viewDateChange)="DateChange()"> Today </div>
                    <div class="btn btn-info" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="DateChange()">
                        Next </div>
                </div>
            </div>
            <div class="col-md-4 text-center">
                <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
            </div>
            <div class="col-md-4 text-right">
                <div class="btn-group">
                    <div class="btn btn-info" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month"> Month </div>
                    <div class="btn btn-success" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week"> Week </div>
                    <div class="btn btn-warning" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day"> Day </div>
                </div>
            </div>
        </div>
        <br>
    <!-- ============================================================== -->
    <!-- Calander Area -->
    <!-- ============================================================== -->
        <div [ngSwitch]="view">
            <mwl-calendar-month-view
                *ngSwitchCase="CalendarView.Month"
                [viewDate]="viewDate"
                [events]="events"
                [refresh]="refresh"
                [activeDayIsOpen]="activeDayIsOpen"
                (dayClicked)="dayClicked($event.day)"
                (eventClicked)="handleEvent('Clicked', $event.event)"
                (eventTimesChanged)="eventTimesChanged($event)"
              >
              </mwl-calendar-month-view>
              <mwl-calendar-week-view
                *ngSwitchCase="CalendarView.Week"
                [viewDate]="viewDate"
                [events]="events"
                [refresh]="refresh"
                (eventClicked)="handleEvent('Clicked', $event.event)"
                (eventTimesChanged)="eventTimesChanged($event)"
              >
              </mwl-calendar-week-view>
              <mwl-calendar-day-view
                *ngSwitchCase="CalendarView.Day"
                [viewDate]="viewDate"
                [events]="events"
                [refresh]="refresh"
                (eventClicked)="handleEvent('Clicked', $event.event)"
                (eventTimesChanged)="eventTimesChanged($event)"
              >
              </mwl-calendar-day-view>
        </div><br/><br/>
        <hr>
        <br/>
    </div>
</div>
