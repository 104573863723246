<div class="card">
    <div class="card-body">
        <h4 class="card-title">{{titolo}}</h4>
        <div *ngIf="hasSearch" class="d-md-flex align-items-center border-top bg-white p-3">
          <form [formGroup]="ricercaform" class="input-group">
            <div class="input-group col-md-6">
              <div class="input-group-prepend ">
                <label class="input-group-text" for="inizio">Inizio</label>
              </div>
              <input formControlName="inizio" [ngClass]="{ 'is-invalid': submittedricerca && f.inizio.errors }" type="date" name="inizio" class="form-control">
              <div *ngIf="submittedricerca && f.inizio.errors" class="invalid-feedback">
                <div *ngIf="f.inizio.errors.required">Campo rihiesto</div>
              </div>
            </div>
          </form>
          <div class="ml-auto mt-3 mt-md-0 d-flex align-items-center p-1">
            <button class="btn btn-outline-info rounded-pill" style="width: 100%;" (click)="Search()" type="button">Cerca</button>
          </div>
        </div>
        <ngx-charts-bar-vertical class="chart-container"  [results]="data" [gradient]="gradient"
            [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabel" [showYAxisLabel]="showYAxisLabel"
            [tooltipDisabled]="tooltipDisabled" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
            [showGridLines]="showGridLines" [barPadding]="barPadding"  [roundDomains]="roundDomains"
            (select)="select($event)">
        </ngx-charts-bar-vertical>
    </div>
</div>
