import { Component, OnInit } from '@angular/core';
import {ApiRestService} from "../_services/api-rest.service";
import {Dashboard, DefaultChartElement} from "../models/dashboard";
import {ClassicType} from "../models/ClassicType";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from "@angular/common";
import {environment} from "../../../environments/environment";
import {ApiPathsService} from "../../api-paths.service";
import {ActivatedRoute, Router} from "@angular/router";
@Component({
  selector: 'app-Dashboard',
  templateUrl: './Dashboard.component.html',
  styleUrls: ['./Dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  periodi: ClassicType[] = [{id:"1", label:"Primo Trimestre"},{id:"2", label:"Secondo Trimestre"},{id:"3", label:"Terzo Trimestre"},{id:"4", label:"Quarto Trimestre"},{id:"A", label:"Anno"}];
  periodo: ClassicType[] = [{id:"1", label:"Primo Trimestre"}];
  anno:number = new Date().getFullYear();
  env = environment;
  filtri = {dataScaduti:null, provincia: null};
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField: "id",
    textField: 'label',
    allowSearchFilter: false,
    allowRemoteDataSearch: false,
  };
  ImportiChartData: Array<any> = [{data:[0], label: "Caricamento"}];
  ImportiChartLabels: Array<any> = ["Caricamento"];
  data: Dashboard;
  tipiClientichart;
  FatturatoChartData: any;
  FatturatoChartLabels: any;
  VendutoCommercialeChartData: any;
  VendutoCommercialeChartLabels: any;
  FatturatoCostiChartData: any;
  ContrattiOfferteValChartData: any;
  ContrattiOfferteChartData: any;
  offertovendutofatturatoincassato: any;
  VendutoSegnalatoreChartLabels: any;
  VendutoSegnalatoreChartData:any;
  constructor(
    private ApiRestService: ApiRestService,
    public translate: TranslateService,
    private apiPaths: ApiPathsService,
    private datepipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    ) {

  }
  ngOnInit() {
    this.filtri.dataScaduti = this.datepipe.transform(new Date, 'yyyy-MM-dd')
    const mese = new Date().getMonth() +1;
    console.log(mese);
    if(mese >9)
      this.periodo = [{id:"4", label:"Quarto Trimestre"}];
    else if(mese >6)
      this.periodo = [{id:"3", label:"Terzo Trimestre"}];
    else if(mese >3)
      this.periodo = [{id:"2", label:"Secondo Trimestre"}];
    else
      this.periodo = [{id:"1", label:"Primo Trimestre"}];
    this.refresh();
  }
  refresh(){
    if(!((this.env['DashboardAddon'] && this.env['DashboardAddon']['giemmea'] ) ?? true))
      this.ApiRestService.GetDashboardData({anno:this.anno, trimestre: this.periodo[0].id}).subscribe((data)=>{
      this.data = data;
      // Importi
      this.ImportiChartData = [{data: data['incassato'].map(x => x.value), label: 'incassato'}];
      this.ImportiChartLabels = data['mesi']

      // Fatturato
      this.FatturatoChartData = [{data: data['fatturato'].map(x => x.value), label: 'fatturato'}];
      this.FatturatoChartLabels = data['mesi']

      // Venduto per commerciale
      this.VendutoCommercialeChartData = data['commerciali'];
      this.VendutoCommercialeChartLabels = data['mesi']
      this.VendutoSegnalatoreChartData = data['segnalatori']
      this.VendutoSegnalatoreChartLabels = data['mesi']

      // Contratti ed offerte
      this.ContrattiOfferteChartData = this.resoconto_to_VerticalBar(
        data['contratti'].map((x, i) => [x.value, data['offerte'][i].value]),
        ['contratti', 'offerte'], data['mesi']
      );
      // Valore contratti e offerte
      this.ContrattiOfferteValChartData = this.resoconto_to_VerticalBar(
        data['contratti_val'].map((x, i) => [x.value, data['offerte_val'][i].value]),
        ['contratti', 'offerte'], data['mesi']
      );

      this.offertovendutofatturatoincassato = this.resoconto_to_VerticalBar(
        data['offerto'].map((x, i) => [x.value, data['venduto'][i].value, data['fatturato'][i].value, data['incassato'][i].value]),
        ['offerto', 'venduto', 'fatturato', 'incassato'], data['mesi']
      );

      // Fatturato/costi
      this.FatturatoCostiChartData = this.resoconto_to_VerticalBar(
        data['fatturato'].map((x, i) => [x.value, data['costi'][i].value, data['incassato'][i].value, data['spese'][i].value]),
        ['fatturato', 'costi', 'incassato', 'speso'], data['mesi']
      );
    });
    else
      this.apiPaths.ClassicPost('/task/task/list', {inizio:"2010-05-10", fine:"2050-05-10", periodo: false, page: 1, size: 1, dataScaduti: this.filtri.dataScaduti, provincia: this.filtri.provincia, tipoScaduti: 'T'}).subscribe(data=>this.data = data)
  }
  ClassicType_to_namevalue(data: ClassicType[]){
    var out:any = [];
    data?.forEach(element=>{
      out.push({name: element.label,value: parseFloat(element.value)})
    })
    return out;
  }
  resoconto_to_VerticalBar(data, nomi:string[],Mesi){
    var out:any = [];
    for (var i=0; i< Mesi.length ; i++){
        var _series = [];
        nomi.forEach((nome,id) => _series.push({name: nome, value: data.length > i && data[i].length>id ? parseFloat(data[i][id]) : 0}))
        out.push({name :Mesi[i], series: _series});
    }
    return out;
  }
  getValues(data: DefaultChartElement[]): number[]{
    var out: number[] = [];
    data.forEach(element=>{
      out.push( parseFloat(element.value.toString()))
    })
    return out;
  }
  getLabels(data: DefaultChartElement[]): string[]{
    var out: string[] = [];
    data.forEach(element=>{
      out.push( element.label);
    });
    return out;
  }

  SetProvincia(provincia: string) {
    this.filtri.provincia = provincia;
    this.refresh();
  }
  goToList(tipo: 'T'|'S'|'I'|null ){
    localStorage.setItem('filtriDashboard', JSON.stringify({dataScaduti: this.filtri.dataScaduti, provincia: this.filtri.provincia, tipoScaduti: tipo}));
    this.router.navigate([this.route.snapshot.queryParams[' task/list/dashboard'] || 'task/list/dashboard',]);
  }
}
