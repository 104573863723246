<div class="ml-auto p-2 nav-item " ngbDropdown >
  <button ngbDropdownToggle aria-haspopup="true" aria-expanded="false" type="button" class=" btn btn-info" >
    <i class="fas fa-print  font-14 mr-1 text-white"></i>
    <span class="ml-1 text-white font-weight-bold">Invia Email</span>
  </button>
  <div class="dropdown-menu-right " style=" padding-left: 20px; padding-right: 20px; width: 350px;" ngbDropdownMenu>
    <div class="row " *ngFor="let item of Lista"  >
      <a class="dropdown-item" (click)="Print(item, Modal)">
        <i class="fa fa-print m-r-5 m-l-5"></i>
        {{item.description}}
      </a>
    </div>
  </div>
</div>


<ng-template #Modal let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="editUserLabel">Stampa</h5>
    <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div *ngFor="let item of selected.fields"  class="col-12">
        <div *ngIf='item.type != "select" && item.type != "firma" && item.type != "hidden" && item.type != "textarea" && item.type != "htmleditor"' class="form-group">
          <label class="control-label col-form-label">{{item.label}}</label>
          <input [type]="item.type" [(ngModel)]="Object[item.name]" class="form-control" [placeholder]="item.label">
        </div>
        <div *ngIf='item.type == "textarea"' class="form-group">
          <label class="control-label col-form-label">{{item.label}}</label>
          <textarea [(ngModel)]="Object[item.name]" class="form-control" [placeholder]="item.label"></textarea>
        </div>
        <div *ngIf='item.type == "htmleditor"' class="form-group">
          <label class="control-label col-form-label">{{item.label}}</label>
          <angular-editor style=" color: black;" [config]="editorConfig" [(ngModel)]="Object[item.name]"></angular-editor>
        </div>
        <div *ngIf='item.type == "select"' class="form-group">
          <label class="control-label col-form-label">{{item.label}}</label>
          <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="''" id="tipo" [data]="item.values"
                                   [(ngModel)]="item.selectedValues">
          </ng-multiselect-dropdown>
        </div>
        <div *ngIf='item.type == "firma"' class="form-group">
          <label class="control-label col-form-label">{{item.label}}</label>
          <div style="padding: 20px;" >
            <signature-pad #pad style="display: inline-block; outline-style: groove;
              outline-color: black;
              outline-width: 0.5px;
              width: 100%;
              height: 200px;"
              [options]="signaturePadOptions"
              (onEndEvent) = "drawComplete(item, pad)"
              ></signature-pad>
              <button (click)="cancellaFirma(pad)"
                class=" btn btn-danger p-2 waves-effect waves-light">
                <span class="ml-1 text-white font-weight-bold"> Pulisci </span>
              </button>
          </div>
        </div>
      </div>
    </div>
    <div class="d-md-flex align-items-center p-3" style="padding-right: 25px;">
      <button (click)="saveModalButton()"
        class="ml-auto btn btn-rounded btn-info p-2 waves-effect waves-light">
        <span class="ml-1 text-white font-weight-bold"> Invia </span>
        <i class="fas fa-upload"></i>
      </button>
    </div>
  </div>
</ng-template>
