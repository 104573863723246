import { ApiRestService } from '../../_services/api-rest.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {Categoria} from "../../../formazione/_Models/categoria";
import {ApiPathsService} from "../../../api-paths.service";

@Component({
  selector: 'app-questionario',
  templateUrl: './questionario.component.html'
})
export class QuestionarioComponent implements OnInit {
  @Input() CheckList: Todo[];
  @Output() CheckListChange = new EventEmitter<Todo[]>();
  @Input() isModifyDisabled: boolean = false;
  @Output() OnCheck = new EventEmitter<Todo[]>();
  @Output() OnSave = new EventEmitter<Todo[]>();
  @Input() restroute: string;
  @Input() filter;
  constructor(private ApiPaths: ApiPathsService, private toastService: ToastrService,) { }
  ngOnInit() {
    if(this.restroute)
    this.ApiPaths.ClassicPost(this.restroute,this.filter).subscribe(data=> {
      if(data){
        this.CheckList = data.todo;
      }
    });

  }
  saveClick() {
    this.OnSave.emit(this.CheckList);
  }

  OnCheckChange(todo: Todo, item:Check) {
    todo.foglie.filter(x=> x?.id != item?.id)?.forEach(check => check.risposta = false);
    this.OnCheck.emit(this.CheckList);
  }

  isNota(item: Todo) {
    return item.foglie.some(x => x.isNota);
  }

  change() {
    this.CheckListChange.emit(this.CheckList);
  }
}
export class Check {
  id: number;
  check: string;
  codice: string;
  esito: string;
  valore: number;
  modifica: boolean;
  categorie: number[];
  conNote: boolean;
  note: string;
  categorieSelezionate: Categoria[];
  risposta: boolean;
  isNota?: boolean;
  constructor(modifica: boolean) {
    this.modifica = modifica;
  }
}
export class Todo {
  id: number;
  foglie: Check[];
  categoria: string;
  figli: Todo[];
}
