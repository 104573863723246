import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {Permission} from "../../../Login/_guards/Permission";
import {Categorie} from "../../../Progetti/Models/Categorie";
import {ApiPathsService} from "../../../api-paths.service";
import {CheckListComponent} from "../check-list/check-list.component";

@Component({
  selector: 'app-CheckBuilder',
  templateUrl: './check-categories.component.html',
  styleUrls: ['./check-categories.component.scss']
})
export class CheckCategoriesComponent implements AfterViewInit {
  @ViewChild('checkListComponent') checkListComponent: CheckListComponent
  @Input() sel_cat_id: number = 0;
  @Input() corso: number;
  modificheAbilitate: boolean = false;
  @Input() categorie: Categorie[] = []
  @Output() categorieChange = new EventEmitter<Categorie[]>();
  Selected: Categorie;
  @Input() routePrefix:string;///anagrafica/categorie/check
  @Input() RestRouteChecklist: string;
  @Input() type: "rest" | "array" = "rest";
  constructor(
    private modalService: NgbModal,
    private apiPaths: ApiPathsService,
    public router: Router,
    public permission: Permission
  ) {
  }
  ngAfterViewInit() {
    if(this.routePrefix && this.checkListComponent) {
      this.checkListComponent.routePrefix = this.routePrefix.replace('/categorie', '')
      this.Refresh();
    }
  }

  AbilitaModifiche() {
    this.modificheAbilitate =  !this.modificheAbilitate;
  }

  Elimina(value: Categorie){
    if(confirm("Sei sicuro di voler eliminare questa categoria?")){
      this.apiPaths.ClassicPost(`${this.routePrefix}/delete`,value).subscribe(data => {
        this.Refresh();
      })
    }
  }
  clicked(cat:Categorie){
    if(cat&&!isNaN(cat.id)){
      this.sel_cat_id = cat.id;
      this.checkListComponent.cat_id = cat.id;
      this.checkListComponent.refreshTable(1);
    }
  }

  openModal(targetModal: any, selected: Categorie) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static'
    });
    this.Selected = selected ?? new Categorie();;
  }

  saveModalButton() {
    if (this.Selected?.id) {
      this.apiPaths.ClassicPost(`${this.routePrefix}/update`,this.Selected).subscribe(data => {
        this.Refresh();
        this.closeBtnClick()
      });
    } else {
      this.Selected['corso'] = this.corso
      this.apiPaths.ClassicPost(`${this.routePrefix}/add`,this.Selected).subscribe(data => {
        this.Refresh();
        this.closeBtnClick()
      });
    }
  }

  closeBtnClick() {
    this.modalService.dismissAll();
  }

  Refresh(){
    console.log(this.type)
    if ( this.type == "rest" && this.routePrefix){
      this.apiPaths.ClassicGet(`${this.routePrefix}/list`).subscribe(data=>this.categorie = data);
    }else {
      this.categorieChange.emit()
    }

  }

}
