<div class="d-md-flex align-items-center  py-2" style="background-color: #87858525">
            <span class="d-flex align-items-center  ">
              <span class="font-weight-bold font-14">{{titolo ?? 'Check'}} &nbsp;&nbsp;&nbsp;</span>
            </span>
</div>
<div class="table-responsive" *ngIf="CheckList">
    <table class="table bg-white table-hover align-middle " style="margin-bottom: 70px;">
      <thead>
      <tr>
        <th scope="col">Check</th>
        <th scope="col">Nome</th>
        <th scope="col" *ngIf="hasCampiAggiuntiviCheckbox(CheckList)">campi</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of CheckList; let i = index">
        <td class="align-middle" >
          <input [(ngModel)]="item.risposta" [disabled]="campoUtility.Disable(campoAggiuntivo, Oggetto)" type="checkbox" (ngModelChange)="checkChange(item)" >
        </td>
        <td class="align-middle" >
          <span>
            {{ item.check}}
          </span>
        </td>
        <td class="align-middle" *ngIf="hasCampiAggiuntiviCheckbox(CheckList)">
           <app-field-viewer [(Oggetto)]="CheckList[i]" [(Campi)]="item.fields" (OnChange)="Change($event)" [(DropdownSources)]="DropdownSources"></app-field-viewer>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
