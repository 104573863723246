import { Component, Input, OnInit } from '@angular/core';
import * as shape from 'd3-shape';
import { generateData } from '../../Dashboard/chartData';

@Component({
  selector: 'app-line-chart',
  templateUrl: './Line-Chart.component.html',
  styleUrls: ['./Line-Chart.component.css']
})
export class LineChart_Component {

  @Input() titolo: string;
  // lineChart
  @Input() lineChartData: Array<any> = [
    { data: [65, 39, 80, 15, 76, 35, 40], label: 'Series A' },
    { data: [18, 58, 20, 69, 16, 27, 90], label: 'Series B' }
  ];
  @Input() lineChartLabels: Array<any> = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July'
  ];
  @Input() lineChartOptions: any = {
    responsive: true,
    lineTension: 0
  };
  @Input() lineChartColors: Array<any> = [
    {
      // grey
      backgroundColor: 'rgba(54,190,166,.1)',
      borderColor: '#36bea6',
      pointBackgroundColor: '#36bea6',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#36bea6'
    },
    {
      // dark grey
      backgroundColor: 'rgb(41,98,255,.1)',
      borderColor: '#2962FF',
      pointBackgroundColor: '#2962FF',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: '#2962FF'
    }
  ];
  @Input() lineChartLegend = true;
  @Input() lineChartType = 'line';

  // events
  public chartClicked(e: any): void {
    // console.log(e);
  }

  public chartHovered(e: any): void {
    // console.log(e);
  }




}
