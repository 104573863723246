<li class="nav-item " ngbDropdown placement="bottom-right" *ngIf="currentUser">
  <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)"
    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    <div class="d-flex no-block align-items-center p-10  m-b-3 rounded-pill" [style.background-color]="bgcolor">
      <div class="rounded">
        <i-feather name="user" class="align-items-center feather-lg"></i-feather>
      </div>
    </div>
  </a>
  <div class="dropdown-menu-right user-dd " ngbDropdownMenu>
    <div class="d-flex no-block align-items-center p-15  m-b-10">
      <div class="rounded">
        <i-feather name="user" class="feather-lg"></i-feather>
      </div>
      <div class="m-l-10">
        <h4 class="m-b-0 font-15">{{ currentUser?.fullName }}</h4>
      </div>
    </div>
    <a class="dropdown-item" *ngIf="!environment.Wp" (click)="Profilo()" href="javascript:void(0)">
      <i class="ti-settings m-r-5 m-l-5"></i>{{'Profilo.profilo' | translate}}</a>
    <a class="dropdown-item" (click)="LogOut()" >
      <i class="fa fa-power-off m-r-5 m-l-5"></i> {{'Profilo.logout' | translate}}</a>
  </div>
</li>
