<div class="modal-header">
  <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="col-3">
    <div class="form-group">
      <label  class="control-label col-form-label">File aggiornato:</label>
      <input type="file" class="upload" (change)="immaginecambiata($event)">
    </div>
  </div>
  <div class="col-6">
    <div class="form-group">
      <label for="descrizione" class="control-label col-form-label">Descrizione</label>
      <textarea type="text" [(ngModel)]='descrizione' class="form-control" id="descrizione" placeholder="Descrizione"></textarea>
    </div>
  </div>
</div>
<div class="d-md-flex align-items-center p-3" style="padding-right: 25px;">
  <button (click)="update()" class="ml-auto btn btn-rounded btn-success p-2 waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> Salva </span>
    <i-feather name="save" class="feather-md "></i-feather>
  </button>
</div>
