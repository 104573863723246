import {environment as environmentProgetti} from './environment.8082'

export const Produzione = {
  apiUrl: 'https://lrn.wcs-servizi.it/api',
  //apiUrl: environmentProgetti.apiUrl,
  //apiUrl: 'https://www.mypit.cloud:8082/api',
  //apiUrl: 'https://work.gbdpservizi.com/lrn/api',
  logoPath: 'assets/images/wcs.jpeg',
}

export const environment = {
  Wp: false,
  hasCustomizer:true,
  production: true,
  apiUrl: Produzione.apiUrl,
  bbbUrl: 'https://newsbs.ddns.net/bigbluebutton',
  langPrefix: './assets/i18n/',
  langSuffix: '_LRN.json',
  loginbgPath: 'assets/images/background/teamwork-3213924.jpeg',
  icon:'assets/images/KlimaIMGxicon.png',
  baseUrl: 'task/list',
  href: '/Login',
  homepages: [
    // {Ruoli: ["AC"], link: "/Cliente/profile"}
  ],
  logoPath: Produzione.logoPath,
  loginMsg: '',
  logoBg: 'skin5', // six possible values: skin(1/2/3/4/5/6),
  navbarbg: 'skin5', // six possible values: skin(1/2/3/4/5/6)
  sidebarbg: 'skin5', // six possible values: skin(1/2/3/4/5/6)
  userCircleColor: "",
  theme: 'light',
  dir: 'ltr', // two possible values: ltr, rtl
  layout: 'vertical', // fixed value. shouldn't be changed.
  sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
  sidebarpos: 'fixed', // two possible values: fixed, absolute
  headerpos: 'fixed', // two possible values: fixed, absolute
  boxed: 'full', // two possible values: full, boxed
  ClientiType: ['C', 'F'],
  IDTecnico: "RT",
  //#region Moduli
  Modules:{
    "twoFactorAuth": true,
    "ForceReset": true,
    "Admin": true,
    "Personale": true,
    "Clienti": true,
    "Interventi": true,
    "Prima_Nota": false,
    "Listini": false,
    "Progetti": false,
    "Prodotti": false,
    "Documenti": false,
    "Scadenzario": false,
    "Spedizioni": false,
    "Stampe": true,
    "Task": true,
    "Shared": true,
    "Dashboard": true,
    "Settings": true,
    "Formazione": true,
    "VersioneCarbotti": false,
    "PartiProdotto": false,
    "Parti": false,
    "Rma": true,
    "Ecommerce": false,
    "ScadenzeFiglie": false,
    "Registrazione": false,
    "Ticket": false,
    "Acquisti": false,
    "taskboard": true,
    "Camera": false,
    "Valutazioni":false
  },
  //#endregion
  //#region Addon
  TaskAddon: {
    checkList : true,
    listTitle: "Lezioni Online",
    hideDateFilter: true,
    Filter: [
      {
        nome: 'Corso',
        source: '/servizi/edizioni/list',
        parameter: [{nome:"page", value: 1}, {nome:"size", value: 2000}, {nome:"chiusa", value: false}],
        responseHasData: true,
        oggetti: ['corso'],
        internalOutput: 'corsoObject',
        OutputField: 'corso',
        modificabile: true,
        inputType: 'singleDropdown',
        placeholder: 'Seleziona un Corso',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'nome',
          searchPlaceholderText: "Cerca",
          allowSearchFilter: true
        },
        class: 'col-lg-3',
        visibleifProhibited: true
      },
    ],
    ListTableHeaderField:[
      {nome: "Corso", oggetti: ['corso'] },
      {nome: "Titolo", oggetti: ['nome'], type: 'replace', replacement: [{original: 'R', fieldexist: 'padre', replace:'Intervento #'},{original: 'R', fieldnotexist: 'padre', replace:'Richiesta #'},{original: 'W', replace:'Attività'},{original: 'C', replace:'CRM'}], color:[] },
    ],
  },
  Personale:{
    Corsi: true,
    Attivita: true,
    File: true,
  },
  ClientiAddOn: {
    codAdhoc:false,
    enable:false,
    importReferenti:true,
    InvioCredReferenti: true,
    info:true,
    commerciale: true,
    segnalatore: true,
    segnalatoreInterno: true,
    sedi: true,
    TipoSedePrimaria: false,
    attivita: true,
    geolocalizzazione: false,
    ruolo: true,
    Modifica: false,
    prodotti: true,
    rma: true,
    accesso: false,
    referenti: true,
    interventi: true,
    note_private:false,
    rating : false,
    ruoliAccesso:false,
    compilazione:false,
    luogoNascita: false,
    dataNascita: false,
    statoCivile:false,
    titoloStudio: false,
    occupazione: false,
    invalidita: false,
    legge104: false,
    diagnosi: false,
    accompagnamento:false,
    pensione: false,
    tutelare: false,
    medico: false,
    telMedico: false,
    terapia:false,
    repartoReferente: true,
  },
  ProdottiAddOn: {
    sottoTitolo: false,
    shortDescription: false,
    classeEnergetica: false,
    tags: false,
    video: false,
    dettaglio:false,
    files: true,
    cart: false,
    codice: false,
    codice_produttore: false,
    tipo: true,
    standby: false,
    tipi:false, // tipi di prodotto (1 - 2ante klima)
    cat_image: true,
    lista_prezzo:true,
    TranslatedDesc: false,
    codAdhoc:false,
    DragSort: false,
    cat_Sort: false
  },
  legenda:[],
  filtrife: [

  ],
  CalendarAddon: {
    managerScadenza : true,
  },
  PartiAddOn: [false],//  categorie associate
  RmaAddOn: {
    taskGenerico: true,
    vociDiCosto: false,
    provaAcquisto:true,
    CategoriaProdotto: true,
    TipoAcquisto: false,
    TipoAcquistoSource: "ClientiType",
    Rivenditore:false,
    NumeroDocumento: false,
    DataDocumento: false,
    Ricevuta: false,
    Targhetta: false,
    CircostanzaDifetto: false,
    DescrizioneDifetto: false,
    Difetto: true,
    Telefono: true,
    Garanzia: true,
    EstensioneGaranzia: true,
    Allegati: true,
    Modello: true,
    Matricola: true,
    Brand: true,
    sottoTask: true,
    headersScadenza:[
      {nome: "Titolo", oggetti: ["nome"]},
      {nome: "Responsabile", oggetti: ['manager'], type: "object", field:['fullName'] },
      {nome: "Chiuso", oggetti: ['data_fine_effettiva'], inputType: 'datetime-local', type: "boolean"},
    ],
    AzioniScadenza:[

      {nome: '', class:' ',  labelClass:'ml-1 text-orange font-weight-bold', type: 'POST', restRoute: '/rma/segnalazione/prendiincarico', capacita: [], icon:'fas fa-user text-orange', placeholder: 'Prendi in carico', successMex: "Preso in carico", parameter: [{nome:"id", parameter: 'id'}, {nome: 'presaInCarico', value: true}]},
      {nome: '', class:' ',  labelClass:'ml-1 text-danger font-weight-bold', type: 'POST', restRoute: '/rma/segnalazione/delete', preAlert: 'Sei sicuro di voler eliminare questo elemento?', capacita: ['gestione-taskboard'], icon:'fas fa-trash text-danger', placeholder: 'Elimina', successMex: 'Elemento eliminato con successo', parameter: [{nome:"id", parameter: 'id'}]},

    ],
    campiScadenza: [
      {
        modificabile: true,
        nome: 'Responsabile',
        oggetti: ['manager'],
        inputType: 'singleDropdown',
        source: '/anagrafica/persone/list',
        responseHasData: true,
        parameter: [{nome:"page", value: 1}, {nome:"size", value: 200}],
        internalOutput: 'managerObject',
        OnFilter: 'xx',
        OnChange: 'xxy',
        OutputField: 'manager',
        placeholder: 'Seleziona',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'fullName',
          searchPlaceholderText: "inserire almeno 3 caratteri",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
        },
        class: 'col-lg-3',
        visibleifProhibited: true,
      },
      {nome: "Files", type: "Files", url:"/rma/segnalazione/file", modificabile:true, class: 'col-lg-12', OnChange: 'OnFilesChange'},
    ],
    Ritiro: {
      Enabled: false,
      Referente: true,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true
    },
    Cliente: {
      Enabled: false,
      Referente: true,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true
    },
    Assistenza: {
      Enabled: false,
      Referente: true,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true,
      Tecnico: false,
    }
  },
  ProgettiAddon: {
    commerciale: true,
    segnalatore: true,
    categoriaVoceDiCosto: true,
    lavorato: true,
    CampiOfferta: [
      {
        modificabile: true,
        nome: 'Offerta.Responsabile',
        oggetti: ['manager'],
        inputType: 'singleDropdown',
        source: 'managers',
        internalOutput: 'managerObject',
        OnFilter: 'filtroManager',
        OnChange: 'OnManagerChange',
        placeholder: 'Seleziona',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'fullName',
          searchPlaceholderText: "inserire almeno 3 caratteri",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
        },
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'cliente.commerciale',
        oggetti: ['commerciale'],
        inputType: 'singleDropdown',
        source: 'managers',
        internalOutput: 'commerciale',
        OnFilter: 'filtroCommerciale',
        placeholder: 'Seleziona',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'fullName',
          searchPlaceholderText: "inserire almeno 3 caratteri",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
        },
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'cliente.segnalatore',
        oggetti: ['clienti'],
        inputType: 'singleDropdown',
        source: 'clienti',
        internalOutput: 'segnalatore',
        OnFilter: 'filtrosegnalatore',
        placeholder: 'Seleziona',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'nominativo',
          searchPlaceholderText: "inserire almeno 3 caratteri",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
          noDataAvailablePlaceholderText: "Nessun Cliente trovato ",
          moreButton: true,
          moreButtonText: "Cerca in AdHoc",
        },
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Colore',
        oggetti: ['colore'],
        inputType: 'color',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-1',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Lavorato',
        oggetti: ['lavorato'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-1',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Oggetto:',
        oggetti: ['oggetto'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Numero Ordine:',
        oggetti: ['codice'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-2',
        placeholder: 'Numero Ordine:',
        visibleifProhibited: true
      },
      {
        prevDivClass: "col-md-12",
        modificabile: true,
        nome: 'Offerta.Creazione',
        oggetti: ['data_creazione'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Offerta.Offerta',
        oggetti: ['data_emissione'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Offerta.Accettazione',
        oggetti: ['data_accettazione'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Offerta.Conferma',
        oggetti: ['data_conferma'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Note',
        oggetti: ['note'],
        inputType: 'textarea',
        titleClass: 'control-label col-form-label',
        class: 'col-md-6',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Note Private',
        oggetti: ['note_private'],
        inputType: 'textarea',
        titleClass: 'control-label col-form-label',
        class: 'col-md-6',
        visibleifProhibited: false
      },
      //   {
      //   modificabile:true,
      //   nome: 'Gestore',
      //   oggetti: ['manager'],
      //   inputType: 'singleDropdown',
      //   source: 'managers',
      //   internalOutput: 'gestoreObject',
      //   OnFilter: 'filtroCommerciale',
      //   placeholder: 'Seleziona',
      //   settings: {
      //     singleSelection: true,
      //     closeDropDownOnSelection: true,
      //     singleOutput:true,
      //     idField: 'id',
      //     textField: 'fullName',
      //     searchPlaceholderText: "inserire almeno 3 caratteri",
      //     allowSearchFilter: true,
      //     allowRemoteDataSearch: true,
      //   },
      //   titleClass: 'control-label col-form-label'  ,
      //   class: 'col-lg-3',
      //   visibleifProhibited: true
      //
      // },
    ],
    CampiSottoOfferta: []
  },
  //#endregion
  Menu: [
    {
      title: "Menu",
      class: "has-arrow",
      label: "Menu",
      extralink: true
    },
    {
      module: "Personale",
      path: "/listautenti/personale",
      title:'menu.PersonaleLabel',
      icon: "far fa-user",
      capacita: ["menu-persone"]
    },
    {
      module: "Clienti",
      path: "/listautenti/aziende",
      title:'menu.clienti',
      icon: "mdi mdi-account-multiple",
      capacita: ["menu-clienti"]
    },
    {
      module: "Task",
      path: "task/list",
      title: 'Lista',
      icon: "mdi mdi-calendar",
    },
    {
      module: "Listini",
      path: "listino/listini",
      title: 'menu.listini',
      icon: "mdi mdi-calendar"
    },
    {
      module: "Acquisti",
      path: "acquisti/acquisti",
      title: 'menu.acquisti',
      icon: "mdi mdi-calendar"
    },
    {
      module: "Valutazioni",
      path: "valutazioni/check/lista",
      title: 'menu.Valutazioni',
      class: "has-arrow",
      icon: "mdi mdi-currency-eur",
      capacita: ["menu-val"],
      submenu: [
        {
          path: "valutazioni/lista",
          title: 'menu.Valutazioni',
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-val"]
        }, {
          path: "valutazioni/check/lista",
          title: 'menu.ValutazioniCheck',
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-val"],
        }
      ]
    },
    {
      module: "Formazione",
      title: 'menu.FormazioneLabel',
      icon: "mdi mdi-school",//mdi-calendar-multiple-check //mdi-engine-outline
      class: "has-arrow",
      capacita: ["menu-servizi", "menu-formazione", "menu-calendar-servizi","menu-utenti-servizi"],
      submenu: [
        {
          path: "formazione/media",
          title: "menu.corsiMultimediali",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-formazione"]
        },
        {
          path: "formazione/corsi",
          title: "menu.corsi",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-calendar-servizi", "menu-servizi"]
        },
        {
          path: "/listautenti/personale/stu",
          title: "menu.studenti",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-utenti-servizi"]
        },
        {
          path: "formazione/materie",
          title: "menu.materie",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu_calendar_tirocini"]
        },
        {
          path: "formazione/sedi",
          title: "menu.sedi",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-formazione"]
        }
      ]
    },
    {
      module: "Stampe",
      path: "stampe/templates",
      title: "menu.StampeLabel",
      icon: "mdi mdi-printer",
      class: "has-arrow",
      capacita: ["menu-stampa","menu-blocchi"],
      submenu: [
        {
          path: "stampe/templates",
          title: "Templates",
          capacita: ["gestione-stampa"]
        },
        {
          path: "stampe/marker",
          title: "Marker",
          capacita: ["menu-stampa"]
        },
        {
          path:"/blocchi/blocchi/list",
          title: "Blocchi",
          capacita: ["menu-blocchi"]
        }
      ]
    },
    {
      module: "Settings",
      path: "settings/settings",
      title: "menu.SettingsLabel",
      icon: "mdi-settings",
      capacita: ["menu-settings"]
    },
    {
      module: "Settings",
      path: "settings/smtp",
      title: "menu.smtp",
      icon: "mdi-settings",
      capacita: ["menu-settings"]
    },
    {
      module: "Admin",
      path: "listautenti/roles",
      title: "Ruoli",
      icon: "mdi mdi-lock-open-outline",
      capacita: ["menu-ruoli"]
    },
    {
      module: "Ticket",
      path: "ticket/list",
      title: "Tickets",
      icon: "mdi mdi-bookmark-outline",
      capacita: ["menu-ticket"]
    },
    {
      notruoliOperativi: ["CA","AC"],
      module: "Ecommerce",
      title: 'menu.EcommerceLabel',
      icon: 'mdi mdi-cart',
      class: 'has-arrow',
      submenu: [
        {
          path: '/ecom/cat',
          title: 'Products',
          icon: 'mdi mdi-cards-variant',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          submenu: []
        },
        {
          path: '/ecom/cart',
          title: 'Cart',
          icon: 'mdi mdi-cart',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          submenu: []
        },
        {
          path: '/ecom/orders',
          title: 'Orders',
          icon: 'mdi mdi-chart-pie',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          submenu: []
        }
      ]
    },
    {
      ruoliOperativi:["AC","C","CA"],
      path: 'Cliente/profile',
      title: "Area Riservata",
      icon: 'mdi mdi-cart',
    }
  ],

};
