import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { environment } from 'src/environments/environment';
import {ApiPathsService} from "../../api-paths.service";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-dynamic-dashboard',
  templateUrl: './dynamic-dashboard.component.html',
  styleUrls: ['./dynamic-dashboard.component.scss']
})
export class DynamicDashboardComponent implements OnInit {
  environment = environment;
  filtri= {inizio: '2023-01-01', fine: '2024-12-31'};
  DashBoardComponent : {titolo: string, valore: any, tipo: string , class: string, clickLink:string, VerticalBarData: {value: any, name: string}[] }[] = [];
  constructor(private apiPath: ApiPathsService, private router: Router, private route: ActivatedRoute, private datepipe: DatePipe ) { }
  ngOnInit(): void {
    const today = new Date();
    const inizioMese = new Date(today.getFullYear(), today.getMonth(), 1);
    const fineMese = new Date() ;
    this.filtri.inizio = this.datepipe.transform( inizioMese,'yyyy-MM-dd');
    this.filtri.fine = this.datepipe.transform( fineMese, 'yyyy-MM-dd');

    this.Refresh();
  }
  CampiFiltro() {
    var output = [];
    if(Array.isArray(environment['DashboardAddon']))
      environment['DashboardAddon']?.forEach((dashboardSector: { url: string, campi: any[], filters:any[] })=>{
        output = dashboardSector.filters;
      })
    else if(environment['DashboardAddon'])
      output = environment['DashboardAddon']['filters'];
    //console.log("Filtri dashboard",output)
    return output;

  }
  OnFilter(value:{OnChange: string, Event: any}){
    this.Refresh();

  }
  Refresh(){
    if(Array.isArray(environment['DashboardAddon'])) {
      environment['DashboardAddon']?.forEach((dashboardSector: { url: string, campi: any[] }) => {
        this.apiPath.ClassicPost(dashboardSector.url, this.filtri).subscribe(data => {
          this.DashBoardComponent = [];
          dashboardSector.campi?.forEach(campo => {
            this.DashBoardComponent.push({
              titolo: campo.nome,
              valore: data[campo?.oggetto],
              VerticalBarData: this.VerticalBarData(data[campo?.oggetto]),
              tipo: campo?.tipo ?? 'text',
              class: campo?.class,
              clickLink: campo.clickLink
            })
          })
        })
      })
      console.log("OK")
    }
  }

  Click(campi: { titolo: string; valore: number | string; tipo: string; class: string; clickLink: string }) {
    window.location.href = campi.clickLink;
   // this.router.navigate([this.route.snapshot.queryParams[campi.clickLink] || campi.clickLink,]);
  }

  VerticalBarLabels(valore: any) {
    return valore.map(x=> x.label);
  }
  VerticalBarData(valore: any) {
    if(!Array.isArray(valore)) return;
    var verticalBarData = valore?.map(x=> {
      return { value: x.value ?? 0, name : x.label ?? 'N/A'}
    }) ?? [];
    console.log("Valore", valore);
    console.log("VerticalBarData",verticalBarData);
    return verticalBarData;
  }
}
/*
* {
    "num_int_giorno": [
        {
            "label": "14\/12\/2023",
            "value": 0
        },
        {
            "label": "15\/12\/2023",
            "value": 0
        },
        {
            "label": "16\/12\/2023",
            "value": 0
        },
        {
            "label": "17\/12\/2023",
            "value": 0
        }
    ],
    "costo_int_giorno": [
        {
            "label": "14\/12\/2023",
            "value": 0
        },
        {
            "label": "15\/12\/2023",
            "value": 0
        },
        {
            "label": "16\/12\/2023",
            "value": 0
        },
        {
            "label": "17\/12\/2023",
            "value": 0
        }
    ],
    "num_tipo_int": [
        {
            "label": "Tipointervento",
            "value": null
        }
    ],
    "costo_tipo_int": [
        {
            "label": "Tipointervento",
            "value": null
        }
    ],
    "valore_beni": [
        {
            "label": "Pacco alimentare",
            "value": "1,000.00"
        },
        {
            "label": "Buono pasto cena",
            "value": "1,860.00"
        },
        {
            "label": "Indumento",
            "value": "1,000.00"
        },
        {
            "label": "Buono pasto pranzo",
            "value": "403.00"
        },
        {
            "label": "Buono pasto merenda",
            "value": "147.00"
        },
        {
            "label": "Prodotti igiene personale",
            "value": "350.00"
        }
    ],
    "utenti_servizio": [
        {
            "label": "Altro",
            "value": 1
        },
        {
            "label": "ASL TA 5",
            "value": 3
        },
        {
            "label": "Carabinieri Martina Franca",
            "value": 0
        },
        {
            "label": "Cartabinieri Crispiano",
            "value": 0
        },
        {
            "label": "Centro Salute Mentale ASL TA 5",
            "value": 0
        },
        {
            "label": "Polizia Municipale  Crispiano",
            "value": 0
        },
        {
            "label": "Polizia Municipale  Martina Franca",
            "value": 0
        },
        {
            "label": "Provenienza test",
            "value": 0
        },
        {
            "label": "Questura Crispiano",
            "value": 0
        },
        {
            "label": "Questura Martina Franca",
            "value": 0
        },
        {
            "label": "Servizio Sociale Comune di Martina Franca",
            "value": 0
        },
        {
            "label": "Servizio Sociale Ufficio di Piano",
            "value": 0
        },
        {
            "label": "Servzio Sociale Comune di Crispiano",
            "value": 0
        },
        {
            "label": "UIEPE Taranto",
            "value": 0
        }
    ],
    "utenti": 6,
    "valore_magazzino": "4,760.00"
}
* */
