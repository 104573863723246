import {Persona} from "../../Admin/models/persona";
import {Time} from "@angular/common";

export class Tempo {
  data: Date| string;
  durata: Date ;
  utente: number;
  utenteObject: Persona;
  utentiSelezionati: Persona[];
  id: number;
  fase: number;
  InModifica: boolean;
  task?: any;
}
