import { Pipe, PipeTransform } from '@angular/core';

import {IDropdownSettings, ListItem} from './multiselect.model';

@Pipe({
    name: 'multiSelectFilter',
    pure: false
})
export class ListFilterPipe implements PipeTransform {
    transform(items: ListItem[], filter: ListItem, _settings: IDropdownSettings): ListItem[] {
        if (!items || !filter) return items;
        return items?.filter((item: ListItem) => this.applyFilter(item, filter, _settings)) ?? [];
    }

    public applyFilter(item: ListItem, filter: ListItem, _settings: IDropdownSettings): boolean {
        if (typeof item[_settings?.textField ?? 'text'] === 'string' && typeof filter[_settings?.textField ?? 'text'] === 'string') {
            return !(filter[_settings?.textField ?? 'text'] && item[_settings?.textField ?? 'text'] && item[_settings?.textField ?? 'text'].toLowerCase().indexOf(filter[_settings?.textField ?? 'text'].toLowerCase()) === -1);
        } else {
            return !(filter[_settings?.textField ?? 'text'] && item[_settings?.textField ?? 'text'] && item[_settings?.textField ?? 'text'].toString().toLowerCase().indexOf(filter.text.toString().toLowerCase()) === -1);
        }
    }
}
