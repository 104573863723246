import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CampoAggiuntivo, Sezione} from "../models/campo-aggiuntivo";
import {Permission} from "../../Login/_guards/Permission";

@Component({
  selector: 'app-sector-viewer',
  templateUrl: './sector-viewer.component.html',
  styleUrls: ['./sector-viewer.component.css']
})
export class SectorViewerComponent implements OnInit {
  @Input() Campi: Sezione[];
  @Output() CampiChange = new EventEmitter();
  @Input() DropdownSources: any;
  @Output() DropdownSourcesChange = new EventEmitter();
  @Input() Oggetto: any;
  @Output() OggettoChange = new EventEmitter();
  @Input() isModifyDisabled;
  @Output() OnFilter = new  EventEmitter<{OnFilter: string, Event: any}>();
  @Output() OnChange = new  EventEmitter<{OnChange: string, Event: any}>();
  constructor(public permission: Permission) { }

  ngOnInit(): void {
  }
  FilterHandler(OnFilter: string, $event: any) {
    this.OnFilter.emit({OnFilter: OnFilter, Event: $event})
  }

  ChangeHandler(OnChange: string, $event: any) {
    this.OnChange.emit({OnChange: OnChange, Event: $event})
  }
}
