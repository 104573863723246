<div *ngIf="CheckList">
  <div class="card"  *ngFor="let todo of CheckList">
    <div class="card-body" >
      <h4 class="card-title">{{todo.categoria}}</h4>
      <div class="table-responsive" *ngIf="todo.foglie?.length>0 && !isNota(todo)">
        <table class="table bg-white table-hover align-middle " style="margin-bottom: 70px;">
          <thead>
            <tr>
              <th>Check</th>
              <th scope="col">Valore</th>
              <th scope="col">Note</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of todo.foglie">
              <td >
                <span >
                  <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
                    <i-feather name="info" class="feather-sm"></i-feather>
                  </span>
                  {{ item.check }}
                </span>
              </td>
              <td class="align-middle" >
                <span >
                  <input [(ngModel)]="item.risposta" (ngModelChange)="change()" [disabled]="isModifyDisabled"  type="checkbox" name="" >
                </span>
              </td>
              <td class="align-middle" *ngIf="item.conNote">
                <span >
                  <textarea [(ngModel)]="item.note" (ngModelChange)="change()" [disabled]="isModifyDisabled" class="form-control" type="text" name="" ></textarea>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row" *ngIf="todo.foglie?.length>0 && isNota(todo)">
        <div class="col-12" *ngFor="let item of todo.foglie">
          <textarea [(ngModel)]="item.note" (ngModelChange)="change()" [disabled]="isModifyDisabled" class="form-control"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
