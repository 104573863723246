<div *ngIf="hasHeader"  class="d-md-flex align-items-center bg-white p-3">
  <span class="d-flex align-items-center p-2 " *ngIf="List">
    <h5 class="mb-0 mr-1 text-dark font-weight-bold font-14"> Tempi:</h5>
    <span class="badge badge-danger badge-pill px-3 font-weight-bold font-14" *ngIf="List">{{List.length}}</span>
  </span>
  <div *ngIf="hasAddButton && ModificheAbilitate && permission.isPermitted('gestione-segnalazione')" class="ml-auto mt-3 mt-md-0">
    <button type="button" class="btn btn-cyan rounded-pill py-2" (click)='add()'><i
      class="fas fa-plus  font-14 mr-1 text-white"></i>
      <span class="ml-1 text-white font-weight-bold">Aggiungi</span>
    </button>
  </div>
</div>
<div class="table-responsive">
  <table class="table bg-white table-hover align-middle mb-0 ">
    <thead>
    <tr>
      <th>Data</th>
      <th scope="col">Ore Lavorate</th>
      <th scope="col">Risorsa</th>
      <th scope="col" *ngIf="ModificheAbilitate && permission.isPermitted('gestione-segnalazione')" >Azioni</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of List">
      <td class="align-middle">
        <span *ngIf="!item.InModifica">
          <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
            <i class="fas fa-wrench"></i>
          </span>
          {{item.data | date: 'dd/MM/yyyy'}}
        </span>
        <div *ngIf="item.InModifica">
          <input [(ngModel)]="item.data"  type="date" name="voce" class="form-control">
        </div>
      </td>
      <td class="align-middle">
        <span *ngIf="!item.InModifica">
          <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
            <i class="fas fa-wrench"></i>
          </span>
          {{item.durata}}
        </span>
        <div *ngIf="item.InModifica">
          <input [(ngModel)]="item.durata" ngAutoFocus type="number" name="voce" class="form-control">
        </div>
      </td>
      <td class="align-middle">
        <span *ngIf="!item.InModifica">
          <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
            <i class="fas fa-wrench"></i>
          </span>
          <span *ngIf="item.utenteObject">
            {{item.utenteObject.fullName}}
          </span>
        </span>
        <div *ngIf="item.InModifica">
          <ng-multiselect-dropdown required #resp="ngModel" [settings]="userDropdownSettings" [placeholder]="'Seleziona'" id="resp" name="resp" [data]="getListapersone()"
                                   [(ngModel)]="item.utentiSelezionati">
          </ng-multiselect-dropdown>
        </div>
      </td>
      <td class="align-middle" *ngIf="ModificheAbilitate && permission.isPermitted('gestione-segnalazione')">
        <a href="javascript:void(0)" *ngIf="item.InModifica" ngbTooltip="Salva" placement="bottom" class="link font-16 text-info font-medium mr-2"
           (click)='save(item)'>
          <i-feather name="save" class="feather-sm"></i-feather>
        </a>
        <a href="javascript:void(0)" *ngIf="!item.InModifica" ngbTooltip="Modifica" placement="bottom" class="link font-16 text-info font-medium mr-2"
           (click)='Edit(item)'>
          <i-feather name="edit-2" class="feather-sm"></i-feather>
        </a>
        <a href="javascript:void(0)" ngbTooltip="Elimina" placement="bottom" class="link font-16 text-danger font-weight-bold"
           (click)='delete(item)'>
          <i-feather name="trash-2" class="feather-sm"></i-feather>
        </a>
      </td>
    </tr>
    </tbody>
  </table>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
  <br>
</div>
