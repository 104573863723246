import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject, Subscription} from "rxjs";
import {AngularFileUploaderComponent} from "../file-uploader/angular-file-uploader.component";
import {AngularFileUploaderConfig} from "../file-uploader/angular-file-uploader.types";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ApiRestService} from "../_services/api-rest.service";
import { AuthenticationService } from 'src/app/Login/_services/authentication.service';

@Component({
  selector: 'app-import-component',
  templateUrl: './import-component.component.html'
})
export class ImportComponentComponent implements OnInit {
  private URL = new BehaviorSubject<string>(null);
  readonly $url = this.URL.asObservable();
  private dataStore: { Url: string, } = {Url: undefined,}
  refreshUrl = () => this.URL.next(Object.assign({}, this.dataStore).Url);
  public updateSelectedUrl(url: string) {
    this.dataStore.Url = url;
    this.refreshUrl();
  }
  subscriptions: Subscription = new Subscription();
  @Input() isModal: boolean = false;
  @Input() parametri: { [param: string]: string | string[] };
  @Output() Uploaded = new EventEmitter<any>();
  @ViewChild('fileUpload')
  private fileUpload:  AngularFileUploaderComponent;
  @Input() afuConfig: AngularFileUploaderConfig = {
    multiple: false,
    maxSize: 5,
    formatsAllowed: "pdf,doc,docx,xls,xlsx,jpg,png,txt,ods,odt,msg,eml",
    uploadAPI:  {
      url: '',
      method: "POST",
      headers: { Authorization: `Bearer ${this.authenticationService?.currentUserValue?.access_token}`,}
      //responseType: 'application/json',
    },
    paramsType: "body",
    theme: "dragNDrop",
    hideProgressBar: false,
    hideResetBtn: false,
    hideSelectBtn: false,
    hideUploadButton: true,
    fileNameIndex: true,
    replaceTexts: {
      selectFileBtn: 'Seleziona il File',
      resetBtn: 'Reset',
      uploadBtn: 'Carica',
      dragNDropBox: 'Trascina Qui il file',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: 'Successfully Uploaded !',
      afterUploadMsg_error: 'Upload Failed !',
      sizeLimit: 'Size Limit'
    }
  };
  constructor(
    private modalService: NgbActiveModal,
    private authenticationService: AuthenticationService,
    private apirest: ApiRestService,
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(this.$url.subscribe(data => this.afuConfig.uploadAPI.url = data));
  }
  saveModalButton() {
    this.fileUpload.uploadFiles(this.parametri);
  }
  closeBtnClick() {
    this.modalService.dismiss();
  }
  onResetClick() {
  }
  DocUploaded(event) {
    this.Uploaded.emit(event);
  }
}
