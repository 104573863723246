import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {AuthenticationService} from '../../Login/_services/authentication.service';
import {environment} from "../../../environments/environment";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService,
    private toastService: ToastrService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      let errorMessage = err.error.message || err.statusText;
      if (err.status === 401 && !environment.Wp && request?.url?.toLowerCase()?.includes('login')) {
        this.toastService.error("Autenticazione Fallita, Credenziali non valide", 'Oops!');
        return throwError(errorMessage);
        //location.reload();
      }
      if (err.status === 401 && !environment.Wp && !request?.url?.toLowerCase()?.includes('login')) {
        this.toastService.error("Accesso negato.\n" + request?.url, 'Oops!');
        this.authenticationService.logout();
        return throwError(errorMessage);
        //location.reload();
      }
      if (err.status === 422) {
        // errore di validazione
        errorMessage = "";
        for (const field in err.error) {
          errorMessage += field + " : " + err.error[field] + '\n';
        }
      }
      if (err.status === 500) {
        errorMessage= `Errore non gestito del server:\n${errorMessage}`
      }
      if (environment.Wp) {
        // errore di validazione
        errorMessage = "";
        for (const field in err.error) {
          errorMessage += field + " : " + err.error[field] + '\n';
        }
      }
      if(request.url.includes(environment.apiUrl))
        this.toastService.error(errorMessage, 'Oops!');
      return throwError(errorMessage);
    }));
  }
}
