import { DownloadService } from '../../shared/_services/download.service';
import { Field } from '../Models/Field';
import { ApiRestService } from '../_service/ApiRest.service';
import { Stampa } from '../Models/Stampa';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignaturePad } from 'angular2-signaturepad';
import {IDropdownSettings} from "ng-multiselect-dropdown";

@Component({
  selector: 'app-stampa',
  templateUrl: './stampa.component.html'
})
export class StampaComponent implements OnInit {
  signaturePadOptions: Object = {
    'minWidth': 5,
    'canvasWidth': 700,
    'canvasHeight': 200
  };
  @Input() Stampe: Stampa[] = [];
  selected: Stampa;
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField:'value',
    textField: 'text',
  };
  @Input() data: any[];
  @Output() dataChange = new EventEmitter;
  constructor(private ApiRestService: ApiRestService,
    private modalService: NgbModal,
    private downloadService: DownloadService) { }

  ngOnInit() {
    console.log(this.Stampe);
  }
  Print(Stampa: Stampa,targetModal: NgbModal){
    console.log(Stampa)
    // var jsonstring: string = "";

    var sendvalue: any = {};
    sendvalue["stampa"] = Stampa.id;
    Stampa.fields.forEach(element => {
      if (element.type == "hidden" && element.values.length > 0)
        sendvalue[element.name] = element.values[0].toString();
      if (element.type == "ids" && this.data?.length > 0)
        sendvalue["ids"] = this.data?.filter(x=> x["isSelected"])?.map(y => y.id);
    })


    // jsonstring = jsonstring + '{ "stampa":' + Stampa.id.toString() +" ";
    // Stampa.fields.forEach(element => {
    //   if (element.type == "hidden" && element.values.length > 0)
    //     jsonstring = jsonstring + ', "' + element.name + '": ' + element.values[0].toString();
    //   if (element.type == "ids" && this.data?.length > 0)
    //     jsonstring = jsonstring + ', "' + element.name + '": ' + ;
    // })
    // jsonstring = jsonstring + '}';
    this.selected = Stampa;
    if(!Stampa.fields || Stampa.fields.length <= 0 || !Stampa.fields.find(x=>x.type != "hidden" && x.type != "ids"))
      this.ApiRestService.print(JSON.stringify(sendvalue)).subscribe(data => this.downloadService.downloadFile(data));
    else
      this.openModal(targetModal);
  }
  saveModalButton() {
    console.log(this.selected);
    var jsonstring: string = "";
    jsonstring = jsonstring + '{ "stampa":' + this.selected.id.toString() +" ";
    this.selected.fields.forEach(element => {
      if (element.type == "hidden" && element.values.length > 0)
        jsonstring = jsonstring + ', "' + element.name + '": ' + element.values[0].toString();
      else if (element.type == "select" && element.selectedValues && element.selectedValues.length > 0 && element.selectedValues[0].value)
        jsonstring = jsonstring + ', "' + element.name + '": "' + element.selectedValues[0].value.toString() + '"';
      else if(element.type == "number")
        jsonstring = jsonstring + ', "' + element.name + '": ' + element.value;
      else if(element.type != "select" && element.type != "hidden" && element.type != "number" && element.value)
        jsonstring = jsonstring + ', "' + element.name + '": "' + element.value + '"';
    })
    jsonstring = jsonstring + '}';
    this.ApiRestService.checkstring(jsonstring).subscribe(data => {
      this.ApiRestService.print(jsonstring).subscribe( data => {
        this.downloadService.downloadFile(data)
        this.modalService.dismissAll();
      });
    });
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }
  openModal(targetModal: NgbModal) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static',
      size:'lg'
    });
  }
  drawComplete(item: Field, pad: SignaturePad) {
    item.value = pad.toDataURL("image/png");
  }
  cancellaFirma(pad: SignaturePad){
    pad.clear();
  }


}
