import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFileUploaderComponent } from './angular-file-uploader.component';
import { HttpClientModule } from '@angular/common/http';
import { FileUpdateModalComponent } from './file-update-modal/file-update-modal.component';
import {FeatherModule} from "angular-feather";
import {FormsModule} from "@angular/forms";

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        FeatherModule,
        FormsModule,
    ],
  declarations: [AngularFileUploaderComponent, FileUpdateModalComponent],
  exports: [AngularFileUploaderComponent]
})
export class AngularFileUploaderModule { }
