import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output,} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Files} from "../../models/File";
import {ApiPathsService} from "../../../api-paths.service";
import {HttpClient} from "@angular/common/http";
import { AuthenticationService } from 'src/app/Login/_services/authentication.service';

@Component({
  selector: 'app-file-update-modal',
  templateUrl: './file-update-modal.component.html',
  styleUrls: ['./file-update-modal.component.css']
})
export class FileUpdateModalComponent implements AfterViewInit {
  private updateFile: Blob;
  selected_file: any;
  @Output() refresh = new EventEmitter();
  @Input() descrizione: string;

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private apipathsservice: ApiPathsService,
    private http: HttpClient,
    private authenticationService: AuthenticationService
  ) { }

  ngAfterViewInit(): void {
    console.log('Selected File', this.selected_file)
    this.descrizione = this.selected_file?.description;
  }

  update() {
    var form = new FormData();
    form.append('allegato', this.updateFile);
    form.append('id', this.selected_file.id?.toString());
    form.append('descrizione', this.descrizione);
    this.http.request('POST', `${this.apipathsservice?.getBaseAPI()}/file/update`, {
      body: form,
      reportProgress: true,
      observe: 'events',
      headers: {
        Authorization: `Bearer ${this.authenticationService?.currentUserValue?.access_token}`,
      },
      params: null,
    })
      .subscribe(
        (event) => {
          this.refresh.emit();
          this.ngbActiveModal.dismiss();
        }
      );
  }
  async immaginecambiata(event) {
    const inpUpload: HTMLInputElement =event.target;
    this.updateFile = inpUpload.files[0];
  }

  closeBtnClick() {
    this.ngbActiveModal.dismiss();
  }

}
