import { Stampa } from './../../task/models/date';
import { Template } from './../Models/Template';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiPathsService } from './../../api-paths.service';
import { AuthenticationService } from './../../Login/_services/authentication.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiRestService {
  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private apiPaths: ApiPathsService,
  ) { }
  headerforfile() {
    return new HttpHeaders({
      // 'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
    });
  }
  header() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
    });
  }
  /**
   * Opzioni di default per le richieste HTTP
   */
  options() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
      }),
    };
  }

  getListaTemplate(): Observable<Template[]> {
    return this.http.get<Template[]>(`${this.apiPaths.getBaseAPI()}/stampa/list`,
      this.options()).pipe(map((data) => {
        const elenco: Template[] = data;
        return elenco;
      }));
  }
  getListaMarkers(): Observable<Template[]> {
    return this.http.get<Template[]>(`${this.apiPaths.getBaseAPI()}/stampa/stampe`,
      this.options()).pipe(map((data) => {
        const elenco: Template[] = data;
        return elenco;
      }));
  }
  check(value:Stampa): Observable<any> {
    return this.http.post<Template[]>(`${this.apiPaths.getBaseAPI()}/stampa/check`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
        const elenco: Template[] = data;
        return elenco;
      }));
  }
  checkstring(value: string): Observable<any> {
    return this.http.post<Template[]>(`${this.apiPaths.getBaseAPI()}/stampa/check`,
      value,
      this.options()).pipe(map((data) => {
        const elenco: Template[] = data;
        return elenco;
      }));
  }
  print(value: string): Observable<any> {
    return this.http.post(`${this.apiPaths.getBaseAPI()}/stampa/print`,
      value,
      { headers: this.header(), observe:'response', responseType:'blob'}).pipe();
  }

}
