<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left">
    <li class="nav-item d-none d-lg-block">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light"
            href="javascript:void(0)">
            <i class="ti-menu"></i>
        </a>
    </li>
    <!-- ============================================================== -->
    <!-- Search -->
    <!-- ============================================================== -->
   <!-- <li class="nav-item search-box">
        <a (click)="showSearch = !showSearch" class="nav-link waves-effect waves-dark" href="javascript:void(0)">
            <i class="ti-search"></i>
        </a>
        <form class="app-search position-absolute" [ngClass]="{'show-search': showSearch}">
            <input type="text" class="form-control" placeholder="Search &amp; enter">
            <a class="srh-btn" (click)="showSearch = !showSearch">
                <i class="ti-close"></i>
            </a>
        </form>
    </li>-->
</ul>

<!-- Navbar Header for horizontal only -->
<div class="navbar-header d-none d-lg-block " style="padding-inline: 20px;">
    <!-- This is for the sidebar toggle which is visible on mobile only -->
    <a (click)="showMobileMenu = !showMobileMenu" class="nav-toggler waves-effect waves-light d-block d-lg-none"
        href="javascript:void(0)">
        <i [ngClass]="(showMobileMenu)?'ti-close':'ti-menu'"></i>
    </a>
    <!-- ============================================================== -->
    <!-- Logo -->
    <!-- ============================================================== -->
    <a class="navbar-brand" href="/">
        <!-- Logo icon -->
        <b class="logo-icon">
            <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
            <!-- Dark Logo icon -->
            <img [src]="environment.logoPath"  alt="homepage" class="dark-logo" style="max-height: 60px; padding-block: 7.5px" />
            <!-- Light Logo icon -->
            <img [src]="environment.logoPath" alt="homepage" class="light-logo" style="max-height: 60px; padding-block: 7.5px" />
        </b>
        <!--End Logo icon -->
    </a>
    <!-- ============================================================== -->
    <!-- End Logo -->
    <!-- ============================================================== -->
    <!-- ============================================================== -->
    <!-- Toggle which is visible on mobile only -->
    <!-- ============================================================== -->
    <a class="topbartoggler d-block d-lg-none waves-effect waves-light" href="javascript:void(0)"
        (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent">
        <i class="ti-more"></i>
    </a>
</div>

<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<!--<ul class="navbar-nav float-right">



    &lt;!&ndash; ============================================================== &ndash;&gt;
    &lt;!&ndash; mega menu &ndash;&gt;
    &lt;!&ndash; ============================================================== &ndash;&gt;
    <li class="nav-item mega-dropdown" ngbDropdown [autoClose]="false">
        <a ngbDropdownToggle class="nav-link waves-effect waves-dark" href="javascript:void(0)" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="mdi mdi-view-grid"></i>
        </a>
        <div class="dropdown-menu" ngbDropdownMenu>
            <div class="mega-dropdown-menu row">
            </div>
        </div>
    </li>
    &lt;!&ndash; ============================================================== &ndash;&gt;
    &lt;!&ndash; End mega menu &ndash;&gt;
    &lt;!&ndash; ============================================================== &ndash;&gt;
    &lt;!&ndash; ============================================================== &ndash;&gt;
    &lt;!&ndash; User profile and search &ndash;&gt;
    &lt;!&ndash; ============================================================== &ndash;&gt;
    <li class="nav-item" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="assets/images/users/1.jpg" alt="user" class="rounded-circle" width="31">
        </a>
        <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
            <div class="d-flex no-block align-items-center p-15  m-b-10">
                <div class="">
                    <img src="assets/images/users/1.jpg" alt="user" class="rounded" width="70">
                </div>
                <div class="m-l-10">
                    <h4 class="m-b-0 font-15">Steave Jobs</h4>
                    <p class="text-muted m-b-0 font-12">varun@gmail.com</p>
                    <a href="javascript:void(0)" class="btn btn-danger btn-rounded btn-sm m-t-5">View Profile</a>
                </div>
            </div>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-user m-r-5 m-l-5"></i> My Profile</a>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-email m-r-5 m-l-5"></i> Inbox</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-settings m-r-5 m-l-5"></i> Account Setting</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
        </div>
    </li>
    &lt;!&ndash; ============================================================== &ndash;&gt;
    &lt;!&ndash; User profile and search &ndash;&gt;
    &lt;!&ndash; ============================================================== &ndash;&gt;
</ul>-->
<ul class="navbar-nav float-right ml-auto">
  <!--  <li class="nav-item ">-->
  <!--    <a href="javascript:void(0)" class="btn-dark btn btn-circle btn-lg"-->
  <!--        (click)="customizer = !customizer">-->
  <!--        <i class="fa fa-spin fa-cog"></i>-->
  <!--    </a>-->
  <!--  </li>-->
  <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
    <a ngbDropdownToggle class="nav-link dropdown-toggle" href="javascript:void(0)" id="navbarDropdown"
       role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="flag-icon flag-icon-{{selectedLanguage.icon}}"></i>
    </a>
    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" ngbDropdownMenu>
            <span class="dropdown-item" href="#" *ngFor="let lang of languages" (click)="changeLanguage(lang)">
                <i class="flag-icon flag-icon-{{lang.icon}}"></i>
                <span>{{lang.language}}
                  <span *ngIf="lang.type">({{lang.type}})</span>
                </span>
            </span>
    </div>
  </li>
<!--  <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
    <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)" id="2"
       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="mdi mdi-bell-outline font-weight-bold font-24"></i>
      <div class="notify" *ngIf="notifiche && notifiche > 0"> <span class="heartbit"></span> <span class="point"></span> </div>
    </a>
    <div class="dropdown-menu dropdown-menu-right mailbox" aria-labelledby="2" ngbDropdownMenu>
      <ul class="list-style-none">
        <li class="border-bottom">
          <div class="drop-title bg-info text-white">Notifiche</div>
        </li>
        <li>
          <div class="message-center message-body" [perfectScrollbar]="config" *ngIf="notifiche && notifiche > 0">
            &lt;!&ndash; Message &ndash;&gt;
            <a href="/scadenzario/scadenzario" class="message-item">
                <span class="btn btn-circle btn-success" style="padding: 0">
                  <i class="mdi mdi-bell-outline font-24"></i>
                </span>
              <span class="mail-contnet">
                    <h5 class="message-title">Hai {{notifiche}} {{notifiche && notifiche > 1 ? "scadenze" : "scadenza"}}</h5>
                </span>
            </a>
          </div>
        </li>
        <li>
          <a class="nav-link text-center text-dark link" href="/scadenzario/scadenzario" *ngIf="notifiche && notifiche > 0">
            <b>Vai al Calendario  </b>
            <i class="fa fa-angle-right"></i>
          </a>
        </li>
      </ul>
    </div>
  </li>-->
  <app-user-dropdown></app-user-dropdown>
</ul>
