import { Component, Input, OnInit } from '@angular/core';
import * as shape from 'd3-shape';
import { generateData } from '../../Dashboard/chartData';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.css']
})
export class PieComponent {

  @Input() titolo: string;
  @Input() single;
  dateData: any[];
  dateDataWithRange: any[];
  @Input() hasSearch = true;
  @Input() tooltipDisabled = false;
  @Input() range = false;
  @Input() gradient = false;
  @Input() colorScheme = {
    domain: ['#4fc3f7', '#fb8c00', '#7460ee', '#f62d51', '#20c997', '#2962FF','#9a5510','#bdd5b5','#47becd','#686f47','#68113c']
  };
  constructor() {
    this.dateData = generateData(6, false);
    this.dateDataWithRange = generateData(2, true);
  }
  get dateDataWithOrWithoutRange() {
    if (this.range) {
      return this.dateDataWithRange;
    } else {
      return this.dateData;
    }
  }
  curve = shape.curveLinear;

  select(data:string) {
    console.log('Item clicked', data);
  }
  onLegendLabelClick(entry:string) {
    console.log('Legend clicked', entry);
  }

}
