import { Paginazione } from './../../shared/models/paginazione';
export class RicercaPaginazione extends Paginazione {
  nome?: string;
  ruolo?: string;
  orderByNome?: string;
  constructor(nome:string, page, size, idRuolo?: string, orderByNome?: string) {
    super(page, size);
    this.nome = nome;
    this.ruolo = idRuolo;
    this.orderByNome = orderByNome;
  }
}
