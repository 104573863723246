import {Component, Directive, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';

import {ApiRestService} from "src/app/Interventi/_services/api-rest.service";
import {PersonaleStoreService} from "../../Admin/_service/personale-store.service";
import {ToastrService} from "ngx-toastr";
import {Persona} from "../../Admin/models/persona";
import {Permission} from "../../Login/_guards/Permission";
import {AuthenticationService} from "../../Login/_services/authentication.service";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {ServerPaginationService} from "../_services/server-pagination.service";
import {DatePipe} from "@angular/common";
import { Tempo } from 'src/app/Interventi/Models/tempo';
import {ApiPathsService} from "../../api-paths.service";

@Component({
  selector: 'app-Shered-tempi-list',
  templateUrl: './tempi-list.component.html',
  styleUrls: ['./tempi-list.component.css']
})
export class SharedTempiListComponent implements OnInit {

  @Input() List: Tempo[] = null;
  @Input() url: string = undefined;
  @Input() Padre: any = undefined;
  @Input() hasAddButton: Boolean = false;
  @Input() hasHeader: Boolean = true;
  @Input() ModificheAbilitate: boolean = true;
  @Input() EditInModal: boolean=true;
  @Output() onChange = new EventEmitter();
  userDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField: 'id',
    textField: 'fullName',
  };
  page = 1;
  pageSize = 10;
  pagesizeserver = 5 // pagine da precaricare da lserver
  newpagegenerated = 1; // pagina per cui è stata fatta la richiesta al server
  collectionSize: number;
  constructor(
    private personaleStore: PersonaleStoreService,
    private ToastService: ToastrService,
    private Permission: Permission,
    private authSvc: AuthenticationService,
    private paginationService: ServerPaginationService,
    public permission: Permission,
    private datePipe: DatePipe,
    private apiPaths: ApiPathsService,
  ) { }

  ngOnInit(): void {
    this.RefreshList(1)
  }
  getPage(page: number) {
    this.page = this.paginationService.paginadellalista(page, this.pagesizeserver);
    const pagetosend = this.paginationService.getPage(page, this.newpagegenerated, this.pagesizeserver);
    if ((pagetosend - 1) % this.pagesizeserver === 0) {
      this.newpagegenerated = page;
      this.RefreshList(pagetosend);
    }
  }
  RefreshList(page: number){
    if(this.List == null && this.Padre && this.Padre.id) {
      var filter = {page: (page - 1) / this.pagesizeserver + 1, size: this.pagesizeserver * this.pageSize, fase: this.Padre.id};
      this.apiPaths.ClassicPost(this.url + '/get', filter).subscribe(data => {
        this.List = data.data;
        this.collectionSize = data.total;
      })
    }
  }

  getListapersone(): Persona[]{
    var out: Persona[] = [];
    if(this.Permission.isPermitted("gestione-segnalazione"))    {
      if (this.Padre.manager)
        out.push(this.Padre.manager)
      if (this.Padre.persone && this.Padre.persone.length > 0)
        this.Padre.persone.forEach(persona => {
          out.push(persona)
        })
      if (this.Padre.partecipantiObject && this.Padre.partecipantiObject.length > 0)
        this.Padre.partecipantiObject.forEach(persona => {
          out.push(persona)
        })
    }else {
      if(this.authSvc.currentUserValue)
        out.push(this.authSvc.currentUserValue)
    }
    return out;
  }

  add() {
    if(!this.List.find(x=>x.InModifica)){
      const toBeAdded = new Tempo();
      toBeAdded.InModifica = true;
      var persone = this.getListapersone().find(x=>x.id == this.authSvc.currentUserValue.id) != undefined ? [this.authSvc.currentUserValue] : [];
      toBeAdded.utentiSelezionati = this.getListapersone()?.length == 1 ? this.getListapersone() : persone;
      toBeAdded.data = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      console.log(toBeAdded)
      this.List.push(toBeAdded)
    }else
      this.ToastService.warning("Attenzione", "Salvare il tempo inserito prima di inserirne uno nuovo.")
  }
  Edit(item: Tempo){
    if(this.List.find(x=>x.InModifica))
      this.ToastService.warning("Attenzione", "Salvare il tempo inserito prima di inserirne uno nuovo.")
    else {
      item.InModifica = true;
    }

  }
  save(item: Tempo) {
    if (!item.data || !item.utentiSelezionati || item.utentiSelezionati.length < 1)
      this.ToastService.warning("Attenzione", "Inserire tutti i campi.")
    const array = this.List.filter(x => x.data == item.data && x.utenteObject && x.utenteObject.id == item.utentiSelezionati[0].id && !x.InModifica);
    if (array && array.length > 1)
      this.ToastService.warning("Attenzione", "Questa data è già presente.")
    else if (!this.Padre)
      this.ToastService.warning("Attenzione", "ID padre non trovato")
    else {
      item.utente = item.utentiSelezionati[0].id;
      item.utenteObject = item.utentiSelezionati[0];
      item.InModifica = false;
      item.task = this.Padre.id;
      if(item.id){
        this.apiPaths.ClassicPost(this.url + '/update', item).subscribe(() => {
          this.onChange.emit();
          this.RefreshList(1)
        },error=>{
          item.InModifica = true;
        })
      }else {
        this.apiPaths.ClassicPost(this.url + '/add', item).subscribe(() => {
          this.onChange.emit();
          this.RefreshList(1)
        },error=>{
          item.InModifica = true;
        })
      }


    }
  }
  delete(item: Tempo) {
    var idx =-1;
    if (item.InModifica && !item.id) {
      idx = this.List.findIndex(x => x == item);
      if (!isNaN(idx))
        this.List.splice(idx, 1)
    } else if(confirm("Sei sicuro di voler eliminare questo elemento?")){
      this.apiPaths.ClassicPost(this.url + '/delete', item).subscribe(() => {
        this.onChange.emit();
        this.RefreshList(1)
      },error=>{
        item.InModifica = true;
      })
    }
  }
}
@Directive({
  selector: '[ngAutoFocus]'
})
export class AutoFocusSharedDirective implements OnInit {
  constructor(private elRef: ElementRef) { }
  ngOnInit(): void {
    this.elRef.nativeElement.focus();
  }
}
