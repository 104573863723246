import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {map} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {TranslateInput, TranslateObj, TranslateOutput} from "../models/translatedesc";
import {ApiPathsService} from "../../api-paths.service";
import {AngularEditorConfig} from "@kolkov/angular-editor";

@Component({
  selector: 'app-translated-desc',
  templateUrl: './translated-desc.component.html'
})

export class TranslatedDescComponent implements OnInit {
  @Input() col4 : boolean = false;
  @Input() data : TranslateObj[] = [];
  @Output() dataChange = new EventEmitter<TranslateObj[]>();
  @Input() col4Ids : string[] = [ "prodotto","descrizione","descrizioneBreve" ];
  @Input() tabbedView: boolean = false;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
    ]
  };
  constructor(private http: HttpClient,private apiPaths: ApiPathsService) { }
  ngOnInit(): void {}

  translate(item: TranslateObj){
    console.log(this.data)
    this.data?.filter(x=>!x.default)?.forEach(translateObj =>{
      translateObj?.TranslateInputs?.forEach((translateInput, index)=>{
        this.gettranslatedtext(item?.TranslateInputs[index],item?.lang, translateObj?.lang, translateInput);
      });
    });
  }

  gettranslatedtext(source: TranslateInput, sourceLang: string, resultLang: string, target: TranslateInput ){
    this.translateService({q: source?.value, source: sourceLang, target: resultLang}).subscribe(data=>{
      target.value = source.id != 'prodotto' ? data.data.translations[0].translatedText : source.value;
    })
  }

  translateService(value: TranslateOutput){
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/language/translate/v2?key=AIzaSyAtHf7gRjLvTbRzwUy4GQ-87hNa--almW0 `,
      JSON.stringify(value),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
        }),
      }).pipe(map((data) => {
        return data;
      })
    );
  }
}

