import {
  Component,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'app-spinner',
  template: `


<!--    <ngx-spinner
    bdColor="rgba(51,51,51,0.5)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
    [fullScreen]="false" style="display: flex; justify-content: center; align-items: center;"
  >

    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>-->

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "square-jelly-box" [fullScreen] = "false"><p style="color: white" > Loading... </p></ngx-spinner>
  `,
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnDestroy {



  ngOnDestroy(): void {

  }
}
