<div class="modal-header" *ngIf="isModal">
  <h5 class="modal-title">Import</h5>
  <button type="button" class="close" (click)="closeBtnClick()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div [ngClass]="{'modal-body': isModal}">
  <div class="row" >
    <div class="col-sm-12 col-md-12">
      <angular-file-uploader #fileUpload [config]="afuConfig"  (onResetClick)="onResetClick()" (ApiResponse)="DocUploaded($event)">
      </angular-file-uploader>
    </div>
  </div>
</div>
<div class="d-md-flex align-items-center p-3" style="padding-right: 25px;">
  <button (click)="saveModalButton()" class="ml-auto btn btn-rounded btn-success p-2 waves-effect waves-light">
    <span class="ml-1 text-white font-weight-bold"> Importa </span>
  </button>
</div>
