import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {CampoAggiuntivo, CampoUtility} from "../models/campo-aggiuntivo";

@Component({
  selector: 'app-checkbox-array-viewer',
  templateUrl: './checkbox-array-viewer.component.html',
  styleUrls: ['./checkbox-array-viewer.component.css']
})
export class CheckboxArrayViewerComponent implements  OnChanges {
  @Input() Oggetto: any;
  @Output() OggettoChange = new EventEmitter();
  @Input() CheckList: {check:string, id: number, risposta: boolean, fields: CampoAggiuntivo[]}[];
  @Output() CheckListChange = new EventEmitter();
  @Input() titolo : string;
  @Output() OnChange = new  EventEmitter<{OnChange: string, Event: any}>();
  @Input() MutualmenteEsclusivi: boolean;
  @Input() campoAggiuntivo: CampoAggiuntivo;

  DropdownSources = {}
  constructor(public campoUtility: CampoUtility) { }

  ngOnChanges(): void {
    this.CheckList?.forEach(check => check.fields?.forEach(field=>{
      field.allowedCapacity = this.campoAggiuntivo?.allowedCapacity;
      field.hideif = this.campoAggiuntivo?.hideif;
      field.disabledif = this.campoAggiuntivo?.disabledif;
      field.AllowedRoles = this.campoAggiuntivo?.AllowedRoles;
      field.prohibitedRoles = this.campoAggiuntivo?.prohibitedRoles;
      field.visibleifProhibited = this.campoAggiuntivo?.visibleifProhibited;
    }))
  }

  hasCampiAggiuntiviCheckbox(array: {check?:string, id?: number, risposta?: boolean, fields?: CampoAggiuntivo[]}[]){
    return array?.find(x=>x.fields?.length > 0) != undefined;
  }

  checkChange(item: { check: string; id: number; risposta: boolean; fields: CampoAggiuntivo[] }) {

    if( this.MutualmenteEsclusivi && item.risposta && item.id == 33 && this.CheckList?.find(x=>x.id == 34))
       this.CheckList.find(x=>x.id == 34).risposta = false;
    else if(this.MutualmenteEsclusivi && item.risposta && item.id == 34 && this.CheckList?.find(x=>x.id == 33))
       this.CheckList.find(x=>x.id == 33).risposta = false;
    this.OnChange.emit({ OnChange: 'getPrice', Event: item });
  }

  Change(event: { OnChange: string; Event: any }) {
    this.OnChange.emit(event);
  }
}
