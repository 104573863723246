<div class="card row p-3">
  <div class="col-12">
    <label for="categorie" class="control-label col-form-label">Modello:</label>
    <ng-multiselect-dropdown [settings]="dropdownSettings" style="width: 80%; " [placeholder]="'Seleziona'" id="categorie" [data]="modelliFiltrati"
       [(ngModel)]="Selection" (ngModelChange)="GetMarkers()" (onFilterChange)="filtroDropdown($event)">
    </ng-multiselect-dropdown>
  </div>
  <div style="padding-left: 20px; padding-right: 20px;">
   <span class="text-dark font-medium font-16" *ngIf="Selection && Selection[0]" >{{Markers}}</span>
  </div>
</div>
