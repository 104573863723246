import { DownloadService } from './../_services/download.service';
import { ApiPathsService } from './../../api-paths.service';
import {HttpClient, HttpEventType, HttpParams} from '@angular/common/http';
import { ApiRestService } from './../_services/api-rest.service';
import { FileStoreService } from '../_services/file-store.service';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ɵConsole
} from '@angular/core';
import { Files } from '../models/File';
import { AngularFileUploaderComponent } from './angular-file-uploader.component';
import { AngularFileUploaderConfig } from './angular-file-uploader.types'
import { Subscription } from 'rxjs';
import {Permission} from "../../Login/_guards/Permission";
import {CampoAggiuntivo} from "../models/campo-aggiuntivo";
import { environment } from 'src/environments/environment';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FileUpdateModalComponent} from "./file-update-modal/file-update-modal.component";
import {AuthenticationService} from "../../Login/_services/authentication.service";

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file-uploader.component.html'
})
export class FileUploaderComponent implements OnInit, OnChanges {
  @Output() onFileSaved = new EventEmitter();
  @Output() onChange = new EventEmitter();
  environment = environment;
  private subscriptions: Subscription = new Subscription();
  @ViewChild('fileUpload') private fileUpload:  AngularFileUploaderComponent;
  @Output() onUploadButtonClick = new EventEmitter<{ [param: string]: string | string[] }>()
  @Input() externalUploadFunc: boolean = false;

  @Input() url: string;
  @Input() listafile: Files[];
  @Output() listafileChange = new EventEmitter<Files[]>();
  @Input() descrizione: string;
  @Input() id: any;
  @Input() idChange = new EventEmitter<any>();
  @Input() campiAggiuntivi: CampoAggiuntivo[] = [];
  @Input() toViewCampiAggiuntivi: CampoAggiuntivo[] = [];
  @Input() name: string;
  @Input() formatsAllowed: string;
  @Input() allowedFiles: File[] = [];
  @Output() allowedFilesChange = new EventEmitter<File[]>();
  parametri: HttpParams = new HttpParams();
  afuConfig: AngularFileUploaderConfig = {
    multiple: false,
    maxSize: 5,
    formatsAllowed: "pdf,doc,docx,xls,xlsx,jpg,png,txt,ods,odt,jpeg,msg,eml",
    uploadAPI:  {
      url: "",
      method: "POST",
      headers: { Authorization: `Bearer ${this.authenticationService?.currentUserValue?.access_token}`,}
      //responseType: 'application/json',
    },
    paramsType: "body",
    theme: "dragNDrop",
    hideProgressBar: false,
    hideResetBtn: false,
    hideSelectBtn: false,
    hideUploadButton: true,
    fileNameIndex: true,
    replaceTexts: {
      selectFileBtn: 'Seleziona il File',
      resetBtn: 'Reset',
      uploadBtn: 'Carica',
      dragNDropBox: 'Trascina Qui il file',
      attachPinBtn: 'Attach Files...',
      afterUploadMsg_success: 'Successfully Uploaded !',
      afterUploadMsg_error: 'Upload Failed !',
      sizeLimit: 'Size Limit'
    }
  };
  collectionSize: number = 0;
  @Input() ListaFile: boolean = true;
  @Input() GestioneFile: boolean = true;
  tosend = {};
  @Input() DropdownSources;
  updateFile: any;
  private selected_file: Files;
  constructor(
    private fileStore: FileStoreService,
    private apirest: ApiRestService,
    public apipathsservice: ApiPathsService,
    private downloadService: DownloadService,
    public permission: Permission,
    private modalService: NgbModal,
    private http: HttpClient,
    public authenticationService: AuthenticationService,
    ){
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes.url && this.url)
      this.afuConfig.uploadAPI.url = this.url.startsWith('/')? `${this.apipathsservice.getBaseAPI()}${this.url}`: this.url;
    if(changes.formatsAllowed && this.url)
      this.afuConfig.formatsAllowed = this.formatsAllowed;
  }
  includesExtensions(item: Files, extensions: string[]){
    return !extensions.every(x=> !item.file.includes(x));
  }

  ngOnInit() {
    console.log("file init", this)
    if(!this.listafile)
    this.subscriptions.add(this.fileStore.$listaFile.subscribe(data => {
      if(data) {
        this.listafile = data
        this.collectionSize = data?.length ?? 0;
      }
    }));
    this.toViewCampiAggiuntivi= [];
    this.campiAggiuntivi?.forEach(campo=> {
      var x = Object.assign({},campo);
      x.modificabile = false;
      this.toViewCampiAggiuntivi.push(x);
    })
    console.log('toViewCampiAggiuntivi', this.toViewCampiAggiuntivi)

  }
  delete(file) {
    if (confirm("Sei sicuro di voler eliminare questo file?"))
    this.fileStore.deleteFile(file).subscribe((data) => {
      this.onChange.emit(data);
    });
  }
  download(file: Files) {
    console.log("download",file.id);
    this.apirest.downloadFle(file.id).subscribe(data => {
      this.downloadService.downloadFile(data)
    });
  }
  onResetClick() {
    this.descrizione = "";
  }
  DocUploaded(event) {
    this.onFileSaved.emit(event);
    this.onChange.emit(event);
  }
  descrizioneCambiata() {
    console.log(this.descrizione);
  }
  Save() {
    if(!this.externalUploadFunc) this.ExternalSave({ 'id': this.id, 'descrizione': this.descrizione }, this.campiAggiuntivi, this.tosend);
    else this.onUploadButtonClick.emit({ 'id': this.id, 'descrizione': this.descrizione })
  }
  ExternalSave(param?:{ [param: string]: string | string[] }, campi?: CampoAggiuntivo[], object?:any){
    this.fileUpload.uploadFiles(param, campi, object);
  }

  view(item: Files) {
    var  url = `${this.apipathsservice?.getBaseAPI()}/file/download/${item.id?.toString()}?token=${this.authenticationService?.currentUserValue?.access_token}&data=${new Date()}`
    console.log(url)
    window.open(url, "_blank");
  }



  openupdatemodal(item: any) {
    console.log(item);

    var editmodal = this.modalService.open(FileUpdateModalComponent, {size: "xl"});
    (<FileUpdateModalComponent>editmodal.componentInstance).selected_file = item;
    (<FileUpdateModalComponent>editmodal.componentInstance).refresh.subscribe(()=> {
      this.onFileSaved.emit();
      this.onChange.emit();
    });
  }
}
