import { DownloadService } from '../../shared/_services/download.service';
import { Field } from '../Models/Field';
import { ApiRestService } from '../_service/ApiRest.service';
import { Stampa } from '../Models/Stampa';
import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SignaturePad } from 'angular2-signaturepad';
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {ToastrService} from "ngx-toastr";
import {ApiPathsService} from "../../api-paths.service";
import {AngularEditorConfig} from "@kolkov/angular-editor";

@Component({
  selector: 'app-InviaEmail',
  templateUrl: './InviaEmail.component.html'
})
export class InviaEmailComponent implements OnInit {
  signaturePadOptions: Object = {
    'minWidth': 5,
    'canvasWidth': 700,
    'canvasHeight': 200
  };
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      {class: 'arial', name: 'Arial'},
      {class: 'times-new-roman', name: 'Times New Roman'},
      {class: 'calibri', name: 'Calibri'},
      {class: 'comic-sans-ms', name: 'Comic Sans MS'}
    ],
    customClasses: [
     /* {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },*/
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'subscript',
        'superscript',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
      ],
      [

        'customClasses',
        'unlink',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };
  @Input() Lista: Stampa[] = [];
  @Output() ListaChange = new EventEmitter<Stampa[]>();
  @Input() restRoute: string = '/progetti/progetti/send';
  selected: Stampa;
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField:'value',
    textField: 'text',
  };
  Object = {};
  @Input() data: any[];
  @Output() dataChange = new EventEmitter;
  constructor(
    private ApiRestService: ApiRestService,
    private apiPath: ApiPathsService,
    private modalService: NgbModal,
    private downloadService: DownloadService,
    private toastrService: ToastrService
    ) { }

  ngOnInit() {
    console.log(this.Lista);
  }
  Print(Stampa: Stampa,targetModal: NgbModal){
    this.Object["stampa"] = Stampa.id;
    Stampa.fields.forEach(element => {
      this.Object[element.name] = element.values[0];
      if (element.type == "ids" && this.data?.length > 0)
        this.Object["ids"] = this.data?.filter(x=> x["isSelected"])?.map(y => y.id);
    })
    this.selected = Stampa;
    if(!Stampa.fields || Stampa.fields.length <= 0 || !Stampa.fields.find(x=>x.type != "hidden" && x.type != "ids")){
      this.apiPath.ClassicPost(this.restRoute, this.Object).subscribe(()=>{this.toastrService.success("Email Inviata con successo");})
    }
    else
      this.openModal(targetModal);
  }
  saveModalButton() {
    console.log(this.selected);
    this.selected.fields.forEach(element => {
      if (element.type == "select" && element.selectedValues && element.selectedValues.length > 0 && element.selectedValues[0].value)
        this.Object[element.name] = element.selectedValues[0].value;
    });
    this.apiPath.ClassicPost(this.restRoute, this.Object).subscribe(()=>{
      this.modalService.dismissAll();
      this.toastrService.success("Email Inviata con successo");
    })
  }
  closeBtnClick() {
    this.modalService.dismissAll();
  }
  openModal(targetModal: NgbModal) {
    this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static',
      size:'lg'
    });
  }
  drawComplete(item: Field, pad: SignaturePad) {
    this.Object[item.name] = pad.toDataURL("image/png");
  }
  cancellaFirma(pad: SignaturePad){
    pad.clear();
  }


}
