import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CampoAggiuntivo, CampoUtility} from "../models/campo-aggiuntivo";
import {Permission} from "../../Login/_guards/Permission";
import {ToastrService} from "ngx-toastr";
import {ApiPathsService} from "../../api-paths.service";

@Component({
  selector: 'app-list-campo-viewer',
  templateUrl: './list-campi-viewer.component.html',
  styleUrls: ['./list-campi-viewer.component.css']
})
export class ListCampiViewerComponent implements OnInit {
  @Input() campo: CampoAggiuntivo;
  @Output() campoChange = new EventEmitter();
  @Input() DropdownSources: any;
  @Output() DropdownSourcesChange = new EventEmitter();
  @Input() Oggetto: any;
  @Output() OggettoChange = new EventEmitter();
  @Input() isModifyDisabled;
  @Input() hideLabel;
  @Output() OnFilter = new  EventEmitter<{OnFilter: string, Event: any}>();
  @Output() OnChange = new  EventEmitter<{OnChange: string, Event: any}>();
  constructor(public permission: Permission, public campoUtility: CampoUtility, private toastService: ToastrService, private apiPaths: ApiPathsService) { }

  ngOnInit(): void {
  }

  IsArray(val){
    return val && Array.isArray(val);
  }

  getObject(campo: CampoAggiuntivo, i:number){
    if(campo.type == 'object' && this.Oggetto[campo.oggetti[i]] && this.Oggetto[campo.oggetti[i]][campo.field[i]] && typeof this.Oggetto[campo.oggetti[i]][campo.field[i]] !== 'string' )
      return  campo.field[i+1] ? this.Oggetto[campo.oggetti[i]][campo.field[i]][campo.field[i+1]] : {}
    else if (this.Oggetto && this.Oggetto[campo.oggetti[i]] && campo.field && (campo.type == 'object' || (typeof this.Oggetto[campo.oggetti[i]] !== 'string' && typeof this.Oggetto[campo.oggetti[i]] !== 'number')))
      return this.Oggetto[campo.oggetti[i]][campo.field[i]]
    else if(this.Oggetto)
      return this.Oggetto[campo.oggetti[i]]
    else return null
  }


}
