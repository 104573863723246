<div class="d-md-flex align-items-center bg-white p-2" *ngIf="HasHeader">
  <span class="d-flex align-items-center p-2 ">
    <h5 class="mb-0 mr-1 text-dark font-weight-bold font-14"> Totale:</h5>
    <span class="badge badge-danger badge-pill px-3 font-weight-bold font-14">{{Lista.length}}</span>
  </span>
  <div class="ml-auto mt-3 mt-md-0" >
  </div>
</div>
<div class="d-md-flex align-items-center border-top bg-white p-3" *ngIf="HasFilter">
</div>
<div class="table-responsive">
  <table class="table bg-white table-hover align-middle mb-0 no-wrap">
    <thead>
    <tr>
      <th >Categoria Commerciale</th>
      <th scope="col" >Listino</th>
      <th scope="col" *ngIf="permission.isPermitted('gestione-promozioni')">Azioni</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of Lista" >
      <td>
          <span class="text-dark font-medium font-16">
            {{ item.nome}}
          </span>
      </td>
      <td>
        <span *ngIf="!item.modifica">
          <span *ngFor="let promozione of item.listini">
            {{ promozione }}
          </span>
        </span>
        <span *ngIf="item.modifica">
          <a *ngIf="item.modifica " href="javascript:void(0)" ngbTooltip="aggiungi" class="link font-16 text-success font-medium mr-2" (click)='addPromozione(item)'>
            <i class="fas fa-plus-circle"></i>
          </a>
          <div *ngFor="let promozione of item.promozioni; let i = index">
            <input [(ngModel)]="promozione.value"  placeholder="Listino" type="text"  class="form-control">
             <a *ngIf="item.modifica " href="javascript:void(0)" ngbTooltip="rimuovi" class="link font-16 text-danger font-medium mr-2" (click)='removePromozione(i, item)'>
            <i class="fas fa-minus-circle"></i>
          </a>
          </div>

        </span>
      </td>
      <td class="align-middle" *ngIf="permission.isPermitted('gestione-promozioni')">
        <a *ngIf="!item.modifica"  href="javascript:void(0)" ngbTooltip="Modifica" class="link font-16 text-info font-medium mr-2"
           (click)='edit(item)'>
          <i-feather name="edit-3" class="feather-sm"></i-feather>
        </a>
        <a *ngIf="item.modifica " href="javascript:void(0)" ngbTooltip="Salva" class="link font-16 text-success font-medium mr-2" (click)='edit(item)'>
          <i-feather name="check" class="feather-sm"></i-feather>
        </a>
        <a *ngIf="!item.modifica " href="javascript:void(0)" ngbTooltip="Elimina"
           class="link font-16 text-danger font-weight-bold" (click)='delete(item)'>
          <i-feather name="trash-2" class="feather-sm"></i-feather>
        </a>
        <a *ngIf="item.modifica" href="javascript:void(0)" ngbTooltip="annulla"
           class="link font-16 text-danger font-weight-bold" (click)='delete(item)'>
          <i  class="fas fas fa-times"></i>
        </a>
      </td>
    </tr>
    </tbody>
  </table>
</div>

