import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ServerPaginationService} from "../../_services/server-pagination.service";
import {Permission} from "../../../Login/_guards/Permission";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {Check} from "../Questionario/questionario.component";
import {ToastrService} from "ngx-toastr";
import {ApiPathsService} from "../../../api-paths.service";

@Component({
  selector: 'app-check-list-shared',
  templateUrl: './check-list.component.html',
  styleUrls: ['./check-list.component.css']
})
export class CheckListComponent implements OnInit {
  classicTypeDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    textField: 'categoria',
    idField: 'id',
  };
  Lista: Check[] = [];
  @Input() cat_id: number;
  page = 1;
  pageSize: number = 50;
  pagesizeserver = 4;
  newpagegenerated: number;
  categorie: any;
  @Input() routePrefix:string;///anagrafica/check
  constructor(
    public permission: Permission,
    private paginationService: ServerPaginationService,
    private route: ActivatedRoute,
    public router: Router,
    private apiPaths: ApiPathsService,
    private toastService: ToastrService
  ) { }

  ngOnInit(): void {
    // this.route.params.subscribe(data => {
    //   this.cat_id = data['type'];
    //   this.refreshTable(1);
    // })
    this.refreshTable(1);
  }

  edit(item: Check) {
    if(item.modifica ){
      if ( item.id){
        item.categorie = [this.cat_id];
        item.modifica = !item.modifica;
        this.apiPaths.ClassicPost(`${this.routePrefix}/update`,item).subscribe(data=>{
          this.refreshTable(this.newpagegenerated);
        });
      }else {
        item.modifica = !item.modifica;
        item.categorie = [this.cat_id];
        this.apiPaths.ClassicPost(`${this.routePrefix}/add`,item).subscribe(data=>{
          this.refreshTable(1);
        });
      }
/*      else
        this.toastService.warning("inserire un nome", "Attenzione!!");*/
    }else {
      item.modifica = !item.modifica;
    }
  }
  add(){
    var list = [new Check(true)];
    list = list.concat(this.Lista)
    this.Lista = list;
  }
  getPage(page: number) {
    this.page = this.paginationService.paginadellalista(page, this.pagesizeserver);
    const pagetosend = this.paginationService.getPage(page, this.newpagegenerated, this.pagesizeserver);
    if ((pagetosend - 1) % this.pagesizeserver === 0) {
      this.newpagegenerated = page;
      this.refreshTable(pagetosend);
    }
  }
  refreshTable(page) {
    if(!this.cat_id) return;
    this.newpagegenerated = page;
    const filter = {page: (page - 1) / this.pagesizeserver +1, size: this.pagesizeserver * this.pageSize, cat_id:this.cat_id}
    this.apiPaths.ClassicPost(`${this.routePrefix}/list`,filter).subscribe(data => this.Lista = data.data)
  }


  delete(item: Check) {
    if(confirm("sei sicuro di voler eliminare questo elemento?"))
      this.apiPaths.ClassicPost(`${this.routePrefix}/delete`,item).subscribe(data=>{
        this.refreshTable(1);
      })
  }
}
