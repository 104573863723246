<div tabindex="0" (blur)="onTouched()" class="multiselect-dropdown" (clickOutside)="closeDropdown()">
  <div [class.disabled]="disabled">
    <span tabindex="-1" class="dropdown-btn"  (click)="toggleDropdown($event)">
      <span *ngIf="!selectedItems || (selectedItems?.length == 0 )">{{_placeholder}}</span>
      <ng-container *ngIf="selectedItems && !_settings.singleOutput">
        <span *ngFor="let item of selectedItems; trackBy: trackByFn;let k = index" class="selected-item-container" >
          <span class="selected-item" *ngIf="item"  [hidden]="k  > (this._settings.itemsShowLimit-1)">
            <span >{{item ? item[this._settings?.textField ?? 'label'] : ''}}&nbsp;</span>
            <a style="padding-left:2px;color:white" (click)="onItemClick($event,item)">x</a>
          </span>
        </span>
      </ng-container>
      <ng-container *ngIf="selectedItems && (selectedItems[this._settings?.textField ?? 'label'] || selectedItems['label']) && _settings.singleOutput ">
        <span  class="selected-item-container" >
          <span class="selected-item"  >
            <span >{{selectedItems && (selectedItems[this._settings?.textField ?? 'label'] || selectedItems['label']) ? selectedItems[this._settings?.textField ?? 'label'] ?? selectedItems['label'] : ''}}&nbsp;</span>
            <a style="padding-left:2px;color:white" (click)="onItemClick($event,selectedItems)">x</a>
          </span>
        </span>
      </ng-container>
      <span [ngClass]="{ 'dropdown-multiselect--active': _settings.defaultOpen }" style="float:right !important;padding-right:4px">
        <!--<span style="padding-right: 15px;" *ngIf="itemShowRemaining()>0">+{{itemShowRemaining()}}</span>-->
        <span class="dropdown-multiselect__caret"></span>
      </span>
    </span>
  </div>
  <div class="dropdown-list" [hidden]="!_settings.defaultOpen">
    <ul class="item1">
      <li (click)="toggleSelectAll()" *ngIf="(_data.length > 0 || _settings.allowRemoteDataSearch) && !_settings.singleSelection && _settings.enableCheckAll && _settings.limitSelection===-1" class="multiselect-item-checkbox" style="border-bottom: 1px solid #ccc;padding:10px">
        <input type="checkbox" aria-label="multiselect-select-all" [checked]="isAllItemsSelected()" [disabled]="disabled || isLimitSelectionReached()" />
        <div>{{!isAllItemsSelected() ? _settings.selectAllText : _settings.unSelectAllText}}</div>
      </li>
      <li class="filter-textbox" *ngIf="_settings.defaultOpen && (_data.length>0 || _settings.allowRemoteDataSearch) && _settings.allowSearchFilter">
        <input type="text" ngAutoFocus (keyup.enter)="SelectFirst()" aria-label="multiselect-search" [readOnly]="disabled"  [placeholder]="_settings.searchPlaceholderText" [(ngModel)]="filter[this._settings?.textField ?? 'label']" (ngModelChange)="onFilterTextChange($event)">
      </li>
    </ul>
    <ul class="item2" [style.maxHeight]="_settings.maxHeight+'px'">
      <li (click)="onItemClick($event,item)" *ngFor="let item of _data | multiSelectFilter: filter : _settings; let i = index;"
          class="multiselect-item-checkbox">
        <input type="checkbox" [attr.aria-label]="item[_settings?.textField ?? 'label']" [checked]="isSelected(item)" [disabled]="disabled || (isLimitSelectionReached() && !isSelected(item)) || item[this._settings?.disabledField ?? 'isDisabled']" />
        <div>{{item[this._settings?.textField ?? 'label']}}</div>
      </li>
      <li class='no-filtered-data' *ngIf="_data.length != 0 && (_data | multiSelectFilter:filter : _settings).length == 0 && !_settings.allowRemoteDataSearch">
        <h6>{{_settings.noFilteredDataAvailablePlaceholderText}}</h6>
        <button class="btn btn-outline-primary" *ngIf="this._settings.moreButton" (click)="onMoreButtonClick.emit(filter)">{{this._settings.moreButtonText ?? 'More'}}</button>
      </li>
      <li class='no-data' *ngIf="_data.length == 0">
        <h6>{{_settings.noDataAvailablePlaceholderText}}</h6>
        <button class="btn btn-outline-primary" *ngIf="this._settings.moreButton" (click)="onMoreButtonClick.emit(filter)">{{this._settings.moreButtonText ?? 'More'}}</button>
      </li>
    </ul>
  </div>
</div>
